import React from "react";
import "./nearbyLocation.scss";

const NearbyLocation = ({ locData }) => {
  return (
    <>
      <div className="loc_container">
        <div className="my_container_2">
          <div className="row loc_row">
          {/* <div className="loc_row"> */}
            {locData.map((item, i) => (
              <div className="col-lg-3 col-md-4 loc_col" key={i}>
               {/* <div className="loc_col" key={i}> */}
                <div className="loc_box">
                  <div className="head_wrapper">
                    <img src={item.img} alt={item.alt} className="icon" />
                    <h4 className="section_subheading30">{item.title}</h4>
                  </div>
                  <div className="desc_wrapper">
                    {item?.locations.map((loc, index)=>(
                    <p className="section_desc" key={index}>{loc}</p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NearbyLocation;
