import React, { useEffect, useState } from "react";
import "./hilview.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  floorplanCircle,
  hillViewLogo,
  leftArrow,
  nagpalLogo,
  rightArrow,
} from "../../images";
import {
  amenitiesData,
  bannerData,
  floorPlanData,
  locationData,
  virtualData,
} from "../../Data/HillViewData";
import FloorPlan from "../../components/FloorPlan/FloorPlan";
import NavigationSlider from "../../components/NavigationSlider/NavigationSlider";
import VirtualWalkthrough from "../../components/VirtualWalkthrough/VirtualWalkthrough";
import NearbyLocation from "../../components/NearbyLocation/NearbyLocation";
import { useWindowSize } from "react-use";
import AnimateImage from "../../components/AnimateImage/AnimateImage";
import CommonCtaV2 from "../../components/CommonCtaHillView/CommonCtaV2";
import HillEqnuireForm from "../../components/HillViewEnquire/HillEqnuireForm";
import Whatsapp from "../../components/Whatsapp/Whatsapp";
import config from "../../config/config";
import helper from "../../helpers/helper";
import http from "../../helpers/http";
import { contactUs } from "../../helpers/constant-words";

const HillView = () => {
  const { width } = useWindowSize();
  const [isMarginLeft, setIsMarginLeft] = useState(0);
  const [showEnquireForm, setShowEnquireForm] = useState(false);
  const [formType, setFormType] = useState(null);

  const handleFormClose = () => {
    setShowEnquireForm(false);
    setFormType(null);
  };

  const handleAmenitiesBrochure = () => {
    setFormType("Amenities");
    setShowEnquireForm(true);
  };

  const handleFloorPlanBrochure = () => {
    setFormType("Floor Plans");
    setShowEnquireForm(true);
  };

  const handleBrochureSubmit = async (formData) => {
    formData["brochure"] = formData?.formType;
    const { data } = await http.post(
      `${config.api_url}/forms/brochure-enquire`,
      formData
    );
    if (data) {
      const pdfFileName = `${formType}.pdf`;
      const pdfPath = `/pdf/brochures/${pdfFileName}`;
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfFileName;
      link.target = "_blank";

      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    }
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '">' +
        (index < 10 ? "0" + (index + 1) : index + 1) +
        "</span>"
      );
    },
  };

  const bannerList = bannerData.map((item, i) => (
    <SwiperSlide key={i}>
      <img
        src={width > 767 ? item.img : item.mbImg}
        alt={item.alt}
        width={width > 767 ? 1920 : 375}
        height={width > 1080 ? 1920 : 812}
        className="banner_img"
      />
    </SwiperSlide>
  ));

  const adjustMargins = () => {
    const windowsize = window.innerWidth;
    const secwidth = document.querySelector(".my_container_2").offsetWidth;
    const calcwidth = windowsize - secwidth;

    if (secwidth) {
      setIsMarginLeft(calcwidth / 2);
    }
  };

  useEffect(() => {
    adjustMargins();
    // Adjust margins when window resizes
    const handleResize = () => adjustMargins();
    window.addEventListener("resize", handleResize);
    // Adjust margins on window load
    window.addEventListener("resize", handleResize);
    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", adjustMargins);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <main className="hill_view_main">
        <section className="hillview_sec1">
          <div className="logo_wrapper">
            <div className="img_wrapper">
              <img src={nagpalLogo} alt="nagpal logo" className="nagpal_logo" />
            </div>
            <img
              src={hillViewLogo}
              alt="hill view logo"
              className="hill_view_logo"
            />
          </div>
          <div className="banner_slider_wrapper">
            <Swiper
              slidesPerView={1}
              pagination={pagination}
              grabCursor={true}
              loop={true}
              modules={[Navigation, Pagination, Autoplay]}
              navigation={{
                nextEl: ".left_arrow",
                prevEl: ".right_arrow",
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              className="banner_slider"
              breakpoints={{
                0: {
                  // pagination: false,
                  pagination: { enabled: false }, // Disable pagination on small screens
                },
                768: {
                  pagination: pagination,
                },
              }}
            >
              {bannerList}
              <div className="banner_arrows">
                <img
                  src={rightArrow}
                  alt="right arrow"
                  className="right_arrow"
                  width={width > 767 ? 72 : 30}
                  height={width > 767 ? 48 : 16}
                />
                <img
                  src={leftArrow}
                  alt="left arrow"
                  className="left_arrow"
                  width={width > 767 ? 72 : 30}
                  height={width > 767 ? 48 : 16}
                />
              </div>
            </Swiper>
            <div className="my_container_2">
              <div className="content_wrapper">
                <p className="banner_desc">16th ROAD, BANDRA (W)</p>
                <h1 className="banner_heading">HILL VIEW</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="hillview_sec2 section140">
          <div className="my_container_2">
            <h2 className="section_heading">ABOUT HILL VIEW</h2>
            <p className="section_desc">
              Having a strong key-hold in Mumbai's Luxury suburban region, we've
              grown to revolutionize the real estate industry. Ethics,
              Experience, Entrepreneurship & Expertise are the 4 E's with which
              we've made our company grow by leaps and bounds. Having a strong
              key-hold in Mumbai's Luxury suburban region, we've grown to
              revolutionize the real estate industry.
            </p>
            {/* <p className="section_desc">
            Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr,
            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
            aliquyam erat. Lorem ipsum dolor sit amet, consetetur dolor ser
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat. Lorem ipsum dolor sit amet,
            consetetur dolor ser sadipscing elitr, sed
          </p> */}
          </div>
        </section>

        <section className="hillview_sec3">
          {/* <div className="animation_wrapper">
          <img
            src={floorplanCircle}
            alt="circle"
            className="floorplan_circle"
          />
        </div> */}
          <AnimateImage animateImage={floorplanCircle} />
          <FloorPlan
            dataList={floorPlanData}
            handleFloorPlanBrochure={handleFloorPlanBrochure}
          />
        </section>

        <section className="hillview_sec4">
          <div className="my_container_2">
            <div className="wrapper_aminites_heading_cta">
              <h2 className="section_heading">AMENITIES</h2>
              {width > 766 && (
                <div
                  onClick={handleAmenitiesBrochure}
                  style={{ cursor: "pointer" }}
                >
                  <CommonCtaV2
                    linkCta={false}
                    // externalLink={true}
                    linkText="Download Brochure"
                    onClick={handleAmenitiesBrochure}
                    // isDownolad={false}
                    // link="#"
                  />
                </div>
              )}
            </div>
            {/* <h2 className="section_heading">AMENITIES</h2>
            {width > 766 && (
                <div
                  onClick={handleAmenitiesBrochure}
                  style={{ cursor: "pointer" }}
                >
                  <CommonCtaV2
                    linkCta={false}
                    // externalLink={true}
                    linkText="Download Brochure"
                    onClick={handleAmenitiesBrochure}
                    // isDownolad={false}
                    // link="#"
                  />
                </div>
              )}  */}
            {/* <div className="content_wrapper">
              <p className="section_desc">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor
              sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy
              eirmod tempor invidunt ut labore et dolore magna aliquyam erat
            </p>
              {width > 766 && (
                <div
                  onClick={handleAmenitiesBrochure}
                  style={{ cursor: "pointer" }}
                >
                  <CommonCtaV2
                    linkCta={false}
                    // externalLink={true}
                    linkText="Download Brochure"
                    onClick={handleAmenitiesBrochure}
                    // isDownolad={false}
                    // link="#"
                  />
                </div>
              )}
            </div> */}
          </div>
          <div
            style={{ marginLeft: `${isMarginLeft}px` }}
            className="navigation_swiper"
          >
            <NavigationSlider navigationSliderData={amenitiesData} />
          </div>
          {width <= 766 && (
            <div
              onClick={handleAmenitiesBrochure}
              style={{ cursor: "pointer" }}
            >
              <CommonCtaV2
                linkCta={false}
                // externalLink={true}
                linkText="Download Brochure"
                onClick={handleAmenitiesBrochure}
                // isDownolad={false}
                // link="#"
              />
            </div>
          )}
          <HillEqnuireForm
            externalShow={showEnquireForm}
            externalHandleClose={handleFormClose}
            formType={formType}
            onBrochureSubmit={handleBrochureSubmit}
          />
        </section>

        <section className="hillview_sec5">
          <div className="my_container_2">
            <h2 className="section_heading">VIRTUAL WALKTHROUGH</h2>
          </div>
          <VirtualWalkthrough walkthroughData={virtualData} />
        </section>

        <section className="hillview_sec6">
          <div className="my_container_2">
            <h2 className="section_heading">NEARBY LOCATIONS</h2>
          </div>
          <NearbyLocation locData={locationData} />
        </section>

        <section className="hillview_sec7">
          <div className="my_container_2">
            <iframe
              title="google map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.770952325124!2d72.8332389!3d19.0738056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90002dae4db%3A0x1b3d297e5c914924!2sL%20Nagpal%20Builders!5e0!3m2!1sen!2sin!4v1732598469382!5m2!1sen!2sin"
              width="100%"
              height="709"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="nagpal_map"
            ></iframe>
          </div>
        </section>

        <section className="hillview_sec8">
          <div className="my_container_2">
            <h2 className="section_heading">
              LOOKING FOR QUALITY CONSTRUCTION?
            </h2>
            {/* <CommonCta
            linkCta={true}
            externalLink={true}
            linkText="Contact Us"
            link="tel:02226467300"
            sameTab={true}
          /> */}
            <CommonCtaV2
              linkCta={true}
              externalLink={true}
              linkText="Contact Us"
              link={`${contactUs}#enquire-now`}
              isDownolad={false}
              sameTab={true}
            />
          </div>
        </section>
        <HillEqnuireForm />
        <Whatsapp />
      </main>
    </>
  );
};

export default HillView;
