import { images } from "../images";

export const banners = [
  {
    id: 1,
    backimg: images.commonban1.image,
    alt: images.commonban1.alt,
    head: "Jaswant Heights, Khar (W)",
    // desc: "An artistic dream turning real",
    desc: "Thriving in the heart of Mumbai",
  },
  {
    id: 2,
    backimg: images.commonban3.image,
    alt: images.commonban3.alt,
    head: "Ruby, Bandra (W)",
    desc: "The impetus on quality isn't negotiable",
  },
  {
    id: 3,
    backimg: images.commonban2.image,
    alt: images.commonban2.alt,
    head: "Jaswant Heights, Khar (W)",
    desc: "We believe and practice the saying - 'Nothing but the best'",
  },
  {
    id: 4,
    backimg: images.commonban4.image,
    alt: images.commonban4.alt,
    head: "Ruby, Bandra (W)",
    desc: "Reimagined fine living",
  },
];

export const milestoneData = [
  {
    title: "HILL VIEW",
    location: "16th ROAD, BANDRA (W)",
    year: 2025,
    link: "/projects/ongoing/hill-view",
  },
  {
    title: "bhagirathi sadan",
    location: "17th Road, Khar (W)",
    year: 2022,
    link: "/projects/ongoing/bhagirathi-sadan",
  },
  {
    title: "siddharth",
    location: "15th Road, Bandra (W)",
    year: 2022,
    link: "/projects/ongoing/le-reve",
  },
  {
    title: "SATNAM LEELA",
    location: "Sindhi Society, Chembur",
    year: 2022,
    link: "/projects/completed/satnam-leela",
  },
  {
    title: "N N TOWER",
    location: "13th Road, Khar (W)",
    year: 2021,
    link: "/projects/completed/nn-tower",
  },
  {
    title: "Khar Hira Premises",
    // title: "Khar Hira Premises Co-Operative Society Ltd.",
    location: "14th Road, Khar (W)",
    year: 2020,
    link: "/projects/completed/hira",
  },
  {
    title: "Fifth Avenue Apartments",
    location: "5TH ROAD, J.V.P.D",
    year: 2020,
    link: "/projects/completed/5th-avenue-apartments",
  },
  {
    title: "Jaswant Heights",
    location: "13th Road, Khar (W)",
    year: 2019,
    link: "/projects/completed/jaswant-heights",
  },
  {
    title: "Ruby",
    location: "29th Road, Bandra (W)",
    year: 2017,
    link: "/projects/completed/ruby",
  },
  {
    title: "Amiya",
    location: "18th Road, Khar (W)",
    year: 2015,
    link: "/projects/completed/amiya",
  },
  {
    title: "Vasant Vihar",
    location: "14th Road, Khar (W)",
    year: 2015,
    link: "/projects/completed/vasant-vihar",
  },
  {
    title: "Chhaya",
    location: "15th Road, Khar (W)",
    year: 2014,
    link: "/projects/completed/chhaya",
  },
  {
    title: "Satnam Sharan",
    location: "Sindhi Society, Chembur",
    year: 2011,
    link: "/projects/completed/satnam-sharan",
  },
  {
    title: "Bhagat",
    location: "14th Road, Bandra (W)",
    year: 2011,
    link: "/projects/completed/bhagat",
  },
  {
    title: "Zahra Court",
    location: "10th Road, Khar (W)",
    year: 2011,
    link: "/projects/completed/zahra-court",
  },
  {
    title: "Anupama",
    location: "14th Road, Khar (W)",
    year: 2010,
    link: "/projects/completed/anupama",
  },
  {
    title: "Uphaar Mandir",
    location: "10th Road, Khar (W)",
    year: 2010,
    link: "/projects/completed/uphaar-mandir",
  },
  {
    title: "Bhagwanti Heights",
    location: "14th Road, Khar (W)",
    year: 2009,
    link: "/projects/completed/bhagwanti-heights",
  },
    {
    title: "Sudhama Niwas",
    location: "16th Road, Khar (W)",
    year: 2007,
    link: "/projects/completed/sudhama-niwas",
  },
  {
    title: "La-Vie",
    location: "14th Road, Khar (W)",
    year: 2004,
    link: "/projects/completed/la-vie",
  },
];

export const ongoingData = [
  {
    id: 1,
    heading: "ONGOING PROJECTS",
    description:
      "Situated on one of the broadest roads in Khar west i.e. 17th Road, This building enjoys a magnificent & breath taking partial sea view form its higher floors. This 14 storey tower boasts of its extravagant façade view and spacious 3 BHK's. A large size gynasium and an outdoor party terrace redefines luxury. A separate tower within the plot just for Parking your luxurious vehicles makes it unique from other Parking systems in the vicinity. ",
    name: "BHAGIRATHI SADAN",
    location: "KHAR WEST, MUMBAI",
    area: "1000 SQ. YARDS",
    space: "2.5BHK, 3BHK, 4BHK",
    img: images.ongoingproject1.image,
    alt: images.ongoingproject1.alt,
  },
  {
    id: 2,
    heading: "ONGOING PROJECTS",
    description:
      "This boutique 10-storey building is in true definition a “Diamond”. Located in close proximity to Linking Road, this building is ideal for those who enjoy the hustle-bustle of Khar-Bandra.",
    name: "HIRA",
    location: "14TH ROAD KHAR WEST, MUMBAI",
    area: "630 SQ. YARDS",
    space: "2BHK & 2.5BHK (JODI POSSIBLE – 4/5 BHK)",
    img: images.ongoingproject2.image,
    alt: images.ongoingproject2.alt,
  },
  {
    id: 3,
    heading: "ONGOING PROJECTS",
    description:
      "Located on a serene and peaceful road of Bandra West, this building boasts of a neo-classical elevation. The name “LE RÊVE”  is a French word which means “The Dream”. ",
    name: "LE REVE",
    location: "Bandra West, Mumbai",
    area: "663 SQ. YARDS",
    space: "1BHK, 2BHK, 3BHK",
    img: images.ongoingproject5.image,
    alt: images.ongoingproject5.alt,
  },
];

export const testimonials = [
  {
    description:
      "A TRUSTWORTHY AND RELIABLE NAME <br />Nagpal Builders ensures in their projects the latest innovations, good quality and meets deadlines very efficiently. I noticed the transparency in business operations while working on a redevelopment project of Siddharth CHS. They are patient approachable down to earth and work collectively with all members.",
    clientname: "Mrs. Sachdev",
    role: "Member of Siddharth CHS",
  },
  {
    description:
      "I have been fortunate to have bought flats from L Nagpal Builders. My relationship dates back to the year 2006. My experience has been great with timely possession of my apartments with all amenities which were promised delivered I can say way above what was promised. The customer-friendly attitude of Raju Nagpal the Chairman of the company along with Vinayak Nagpal his son ably assists him in taking L Nagpal Builders LLP to greater heights as amongst the top 3 developers in Bandra Khar Santacruz Chembur areas.",
    clientname: "Mr. Anand",
    role: "Buyer/Investor",
  },
  {
    description:
      "Our experience with L Nagpal Builders has been above and beyond our expectations. Their commitment to quality is unparalleled. The entire team is extremely warm to deal with. The building amenities such as the gymnasium, swimming pool, and rooftop are of top-notch standards without any compromise on quality. They constantly deliver more than they have committed. A special mention and note of thanks to Mr Vinayak Nagpal for invoking a great sense of trust in the company for all our future requirements too.",
    clientname: "Mr. Raheja",
    role: "Member of NN Tower",
  },
  {
    description:
      "1) Excellent and luxurious premises with adjustable deals. <br />2) NAGPAL BUILDER IS SPREAD WITH MULTI PROJECTS ALL OVER KHAR.",
    clientname: "Mr. Brite",
    role: "Buyer in Jaswant Heights",
  },
  {
    description:
      "L Nagpal Builders has always kept up to their commitments and delivered what they have promised me. I shall be happy to continue my relationship with them even in the future.",
    clientname: "Mr. Mansukhani",
    role: "Buyer/Investor",
  },
  {
    description:
      "Buying a new house is a big decision for any family, and The Nagpals made sure that we got the right comfort and confidence to make that decision. Vinayak made sure he understood all our concerns, answered all our questions and was open-minded in figuring out a deal that works in favour of both parties. Even the post-sale experience has been quite smooth, and the Nagpal’s have been fully available and approachable with everything. Their work is top-notch, in terms of the quality of construction. They build homes knowing exactly what buyers will be looking for. I would recommend them to everyone, and would certainly go back to them for any future real-estate requirements. Vinayak and Raju Uncle are genuine and honest and made our overall home-buying experience easy and stress-free.",
    clientname: "Mr. Zode",
    role: "Buyer in Jaswant Heights",
  },
  {
    description:
      "We have had a great experience dealing with L Nagpal Builders for our society's redevelopment, they are very professional and patient in dealings.",
    clientname: "Mr. Bhagchandani",
    role: "Society Member in Siddharth CHS",
  },
];
