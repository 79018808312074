import React from "react";
import "./navigationSlider.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { blackLeftArrow, blackRightArrow } from "../../images";

const NavigationSlider = ({ navigationSliderData }) => {
  return (
    <>
      <div className="navigation_slider_container">
        <Swiper
          slidesPerView={2.7}
          spaceBetween={40}
          grabCursor={true}
          //   centeredSlides={true}
          loop={true}
          modules={[Navigation, Autoplay]}
          navigation={{
            nextEl: ".nav_right_arrow",
            prevEl: ".nav_left_arrow",
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            767: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 2.7,
            },
          }}
          className="navigation_slider"
        >
          {navigationSliderData.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="slider_box">
                <div className="img_navigaion_slider_wrap">
                  <img src={item.img} alt={item.alt} className="slider_img" />
                  <p className="render_image_text_navigation_slider">This is a rendered image*</p>
                </div>
                <h4 className="section_subheading24">{item.title}</h4>
              </div>
            </SwiperSlide>
          ))}
          <div className="arrows_wrapper">
            <img
              src={blackLeftArrow}
              alt="left arrow"
              className="nav_left_arrow"
            />
            <img
              src={blackRightArrow}
              alt="right arrow"
              className="nav_right_arrow"
            />
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default NavigationSlider;
