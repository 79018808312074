import React, { useEffect, useRef } from "react";
import "./animateImage.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useWindowSize } from "react-use";

gsap.registerPlugin(ScrollTrigger);

const AnimateImage = ({ animateImage }) => {
  const { width } = useWindowSize();
  const circleRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        circleRef.current,
        {
          clipPath:
            width > 767 ? "circle(25% at 50% 50%)" : "circle(35% at 50% 50%)",
        }, // Initial clip-path
        {
          clipPath: "circle(75% at 50% 50%)", // Target clip-path
          scrollTrigger: {
            trigger: circleRef.current, // Element triggering the animation
            start: width > 767 ? "top top" : "top center", // Start animation when element is in center of viewport
            end: "bottom center", // End animation when element exits viewport
            scrub: 1, // Smoothly tie animation to scroll
            // markers: true, // Show debug markers
            // snap: {
            //   // duration: { min: 0.2, max: 3 },
            // },
          },
        }
      );
      // console.log("path is", gsap);
    });

    return () => ctx.revert(); // Cleanup GSAP context on component unmount
  }, [width]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 200);
  }, []);

  return (
    <>
      <div className="sticky_circle_wrap">
        <div className="sticky_circle">
          <div className="sticky_circle_element" ref={circleRef}>
            <img
              src={animateImage}
              alt="animated circle"
              width={width > 767 ? 550 : 216}
              height={width > 767 ? 550 : 216}
              className="animate_img"
            />
          </div>
          <div className="half_height_bg"></div>
        </div>
      </div>
    </>
  );
};

export default AnimateImage;
