import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import {
  facebookfooter,
  linkedinfooter,
  logogrey,
  twitterfooter,
  instagramfooter,
  youtubefooter,
  rightarroworange,
  callblack,
  mailblack,
  faqopen,
} from "../../images";
import {
  career,
  faq,
  hillview,
  homepage,
  partner,
  privacypolicy,
  projectsRoute,
  termsAndConditionsRoute,
} from "../../helpers/constant-words";
import { Fade } from "react-awesome-reveal";
import { Accordion } from "react-bootstrap";
import { findAll as getAllProjects } from "../../Data/projects";

const Footer = () => {
  const { width: windowWidth } = useWindowSize();
  const projectsData = getAllProjects();
  const [footerPadding, setfooterPadding] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // console.log("Current Path:", location.pathname); // ✅ Check current URL
    // console.log("Hillview Path:", hillview); // ✅ Check hillview value
  
    if (location.pathname === hillview) {
      setfooterPadding(true);
      // console.log("Padding Applied ✅");
    } else {
      setfooterPadding(false);
      // console.log("Padding Removed ❌");
    }
  }, [location.pathname]);
  

  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Fade duration="2000" triggerOnce>
        {windowWidth > 1023 ? (
          <footer className="footer">
            <img
              src={rightarroworange}
              alt="Nagpal up arrow"
              loading="lazy"
              className="footeruparrow"
              onClick={handleClick}
            />
            <div className="my_container footer_first_row">
              <div className="row m-0">
                <div className="col-md-3 logo_container">
                  <a href={homepage}>
                    <img
                      src={logogrey}
                      alt="Nagpal footer logo"
                      loading="lazy"
                      className="footer_logo"
                    />
                  </a>
                  {/* <div className="social_links_wrapper">
                    <a
                      href="https://in.linkedin.com/"
                      target="_blank"
                      className="social_link"
                      rel="noreferrer"
                    >
                      <img
                        src={linkedinfooter}
                        alt="Nagpal insta logo"
                        loading="lazy"
                        className="social_img"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/"
                      target="_blank"
                      className="social_link"
                      rel="noreferrer"
                    >
                      <img
                        src={youtubefooter}
                        alt="Nagpal linkedin logo"
                        loading="lazy"
                        className="social_img"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      className="social_link"
                      rel="noreferrer"
                    >
                      <img
                        src={facebookfooter}
                        alt="Nagpal fb logo"
                        loading="lazy"
                        className="social_img"
                      />
                    </a>
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      className="social_link"
                      rel="noreferrer"
                    >
                      <img
                        src={twitterfooter}
                        alt="Nagpal twitter logo"
                        loading="lazy"
                        className="social_img"
                      />
                    </a>
                  </div> */}
                  <div className="contact_info">
                    <p className="contact_title">GET IN TOUCH</p>
                    <div className="call_info info">
                      <div className="call_icon">
                        <img src={callblack} loading="lazy" alt="call"></img>
                      </div>
                      <div className="text">
                        <span className="number">
                          {/* <a href="tel:+91 9876543210">+91 9876543210</a> */}
                          <a href="tel:022 26467300">022 26467300</a>
                        </span>
                      </div>
                    </div>
                    <div className="mail_info info">
                      <div className="mail_icon">
                        <img src={mailblack} loading="lazy" alt="mail"></img>
                      </div>
                      <div className="text">
                        <span className="number">
                          <a href="mailto:lnagpalbuilders@gmail.com">
                            lnagpalbuilders@gmail.com
                          </a>
                          {/* <a href="mailto:info@nagpaldevelopers.com">
                            info@nagpaldevelopers.com
                          </a> */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="box_title">Ongoing Projects</div>

                  <div className="box_content_wrapper">
                    {projectsData.map(
                      (item, i) =>
                        i <= 6 && (
                          <React.Fragment>
                            {item.status === "ongoing" && (
                              <div className="link_name_wrapper">
                                <a
                                  key={item.id}
                                  href={`/projects/${item.status}/${item.slug}`}
                                  className="link_name"
                                >
                                  {item.title}
                                </a>
                              </div>
                            )}
                          </React.Fragment>
                        )
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box_title">Completed Projects</div>
                  <div className="box_content_wrapper">
                    {projectsData.map(
                      (item, i) =>
                        i <= 7 && (
                          <React.Fragment>
                            {item.status === "completed" && (
                              <div className="link_name_wrapper">
                                <a
                                  key={item.id}
                                  href={`/projects/${item.status}/${item.slug}`}
                                  className="link_name"
                                >
                                  {item.title}
                                </a>
                              </div>
                            )}
                          </React.Fragment>
                        )
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box_title">Quick Links</div>
                  <div className="box_content_wrapper">
                    <div className="link_name_wrapper">
                      <NavLink to={career} className="link_name">
                        Careers
                      </NavLink>
                    </div>
                    <div className="link_name_wrapper">
                      <NavLink to={partner} className="link_name">
                        Partner with us
                      </NavLink>
                    </div>
                    <div className="link_name_wrapper">
                      <NavLink to={faq} className="link_name">
                        FAQs
                      </NavLink>
                    </div>
                    {/* <div className="link_name_wrapper">
                      <NavLink to="#" className="link_name">
                        Terms & Conditions
                      </NavLink>
                    </div> */}
                    <div className="link_name_wrapper">
                      {/* <a href="https://docs.google.com/document/d/1DlHiBVVrMMPW00_Pz_GAWKiw7P0hyO0i/edit" target="_blank" className="link_name">
                        Disclaimer
                      </a> */}
                      <NavLink to={privacypolicy} className="link_name">
                        Privacy Policy
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my_container footer_second_row">
              <div className="footer_second_content">
                <div className="row m-0 second_row_content">
                  <div className="col-lg-6 text-left social_icons">
                    {/* <div className="footer_icon linkedin">
                      <a
                        href="https://in.linkedin.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={linkedinfooter}
                          alt="Nagpal insta logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    {/* <div className="footer_icon youtube">
                      <a
                        href="https://www.youtube.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={youtubefooter}
                          alt="Nagpal linkedin logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    {/* <div className="footer_icon facebook">
                      <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={facebookfooter}
                          alt="Nagpal fb logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    {/* <div className="footer_icon facebook">
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={twitterfooter}
                          alt="Nagpal twitter logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    <div className="footer_icon instagram">
                      <a
                        href="https://www.instagram.com/lnagpalbuilders/?igshid=YmMyMTA2M2Y%3D"
                        // href="https://www.instagram.com/nagpaldevelopers/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={instagramfooter}
                          alt="Nagpal instagram logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 text-right copyright_text">
                    {/* Copyright © {new Date().getFullYear()} Nagpal */}
                    Copyrights L Nagpal Builders 2023 <sup>TM</sup> | Crafted by{" "}
                    <a
                      href="https://www.togglehead.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Togglehead
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        ) : (
          <footer className="footer">
            <img
              src={rightarroworange}
              alt="Nagpal up arrow"
              loading="lazy"
              className="footeruparrow"
              onClick={handleClick}
            />
            <div className="my_container footer_first_row">
              <div className="row m-0">
                <div className="col-lg-3 ft_logo">
                  <a href={homepage}>
                    <img
                      src={logogrey}
                      alt="Nagpal footer logo"
                      loading="lazy"
                      className="footer_logo"
                    />
                  </a>
                </div>
                <div className="col-lg-3 ft_content">
                  <div className="footer_contact_details_container">
                    <div className="contact_info">
                      <p className="contact_title">GET IN TOUCH</p>
                      <div className="call_info info">
                        <div className="icon">
                          <img src={callblack} loading="lazy" alt="call"></img>
                        </div>
                        <div className="text">
                          <span className="number">
                            {/* <a href="tel:+91 9876543210">+91 9876543210</a> */}
                            <a href="tel:022 26467300">022 26467300</a>
                          </span>
                        </div>
                      </div>
                      <div className="mail_info info">
                        <div className="icon">
                          <img src={mailblack} loading="lazy" alt="mail"></img>
                        </div>
                        <div className="text">
                          <span className="number">
                            {/* <a href="mailto:info@nagpaldevelopers.com">
                              info@nagpaldevelopers.com
                            </a> */}
                            <a href="mailto:lnagpalbuilders@gmail.com">
                              lnagpalbuilders@gmail.com
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="footer_contact_detail_box">
                      <div className="footer_contact_title">contact us</div>
                      <div className="footer_contact_desc">
                        <a href="tel: +912536639100">+91 253 6639100</a>
                      </div>
                    </div> */}
                    {/* <div className="footer_contact_detail_box">
                      <div className="footer_contact_title">e-mail id</div>
                      <div className="footer_contact_desc">
                        <a href="mailto: contact@Nagpal.in">
                          contact@Nagpal.in
                        </a>
                      </div>
                    </div> */}
                    <div className="projects">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <p className="ong_pr">ONGOING PROJECTS</p>
                            <img
                              className="float-right icon"
                              src={faqopen}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              {projectsData.map(
                                (item, i) =>
                                  i <= 6 && (
                                    <React.Fragment>
                                      {item.status === "ongoing" && (
                                        <li>
                                          <a
                                            key={item.id}
                                            href={`/projects/${item.status}/${item.slug}`}
                                          >
                                            {item.title}
                                          </a>
                                        </li>
                                      )}
                                    </React.Fragment>
                                  )
                              )}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <p className="ong_pr">COMPLETED PROJECTS</p>
                            <img
                              className="float-right icon"
                              src={faqopen}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              {projectsData.map(
                                (item, i) =>
                                  i <= 7 && (
                                    <React.Fragment>
                                      {item.status === "completed" && (
                                        <li>
                                          <a
                                            key={item.id}
                                            href={`/projects/${item.status}/${item.slug}`}
                                          >
                                            {item.title}
                                          </a>
                                        </li>
                                      )}
                                    </React.Fragment>
                                  )
                              )}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <p className="ong_pr">QUICK LINKS</p>
                            <img
                              className="float-right icon"
                              src={faqopen}
                              alt="arrow icon"
                            ></img>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul>
                              <li>
                                <NavLink to={career}>Careers</NavLink>
                              </li>
                              <li>
                                <NavLink to={partner}>Partner with us</NavLink>
                              </li>
                              <li>
                                <NavLink to={faq}>FAQs</NavLink>
                              </li>
                              {/* <li>
                                <NavLink to="#">Terms & Conditions</NavLink>
                              </li> */}
                              <li>
                                <NavLink to={privacypolicy}>
                                  Privacy Policy
                                </NavLink>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_second_row" style={footerPadding ? { marginBottom: "5.5em" } : {}}>
              <div className="my_container">
                <div className="row m-0 second_row_content">
                  <div className="col-lg-6 social_icons">
                    {/* <div className="footer_icon linkedin">
                      <a
                        href="https://in.linkedin.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={linkedinfooter}
                          alt="Nagpal insta logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    {/* <div className="footer_icon youtube">
                      <a
                        href="https://www.youtube.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={youtubefooter}
                          alt="Nagpal linkedin logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    {/* <div className="footer_icon facebook">
                      <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={facebookfooter}
                          alt="Nagpal fb logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    {/* <div className="footer_icon facebook">
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={twitterfooter}
                          alt="Nagpal twitter logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div> */}

                    <div className="footer_icon instagram">
                      <a
                        href="https://www.instagram.com/lnagpalbuilders/?igshid=YmMyMTA2M2Y%3D"
                        // href="https://www.instagram.com/nagpaldevelopers/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                        className="social_link"
                        rel="noreferrer"
                      >
                        <img
                          src={instagramfooter}
                          alt="Nagpal instagram logo"
                          loading="lazy"
                          className="social_img"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 copyright_text">
                    {/* Copyright © {new Date().getFullYear()} Nagpal */}
                    Copyrights L Nagpal Builders 2023 <sup>TM</sup> | Crafted by{" "}
                    <a
                      href="https://www.togglehead.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Togglehead
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )}
      </Fade>
    </>
  );
};

export default Footer;
