import { HillViewPdf, images } from "../images";

export const bannerData = [
  {
    img: images.banner01.image,
    mbImg: images.bannerMb01.image,
    alt: images.banner01.alt,
  },
  {
    img: images.banner02.image,
    mbImg: images.bannerMb02.image,
    alt: images.banner02.alt,
  },
  {
    img: images.banner03.image,
    mbImg: images.bannerMb03.image,
    alt: images.banner03.alt,
  },
];

export const floorPlanData = [
  {
    id: 1,
    heading: "GROUND FLOOR",
    title: "Customer Communication Management (CCM HUB)",
    description: [
      `<span class='orange'>Floor Amenities</span> - Grand Entrance Lobby, Mechanical Parking, Pump Room, Parking`,
    ],
    rera: "RERA No : 1234567890",
    planpdf: HillViewPdf,
    img: images.floorPlanGRD.image,
    alt: images.floorPlanGRD.alt,
  },
  {
    id: 2,
    heading: "MEZZANINE FLOOR",
    title: "Customer Experience Management",
    description: [
      `<span class='orange'>Floor Amenities</span> - Grand Entrance Lobby, Mechanical Parking, Pump Room, Parking`,
    ],
    rera: "RERA No : 1234567891",
    planpdf: HillViewPdf,
    img: images.floorPlanMEZZA.image,
    alt: images.floorPlanMEZZA.alt,
  },
  // {
  //   id: 3,
  //   heading: "FIRST FLOOR",
  //   title: "Personalised Data Driven Audio Video Messaging based on AI",
  //   description: [
  //     `<span class='orange'>Floor Amenities</span> - Grand Entrance Lobby, Mechanical Parking, Pump Room, Parking`,
  //   ],
  //   rera: "RERA No : 1234567892",
  //   planpdf: "",
  //   img: images.floorPlanFST.image,
  //   alt: images.floorPlanFST.alt,
  // },
];

export const amenitiesData = [
  {
    id: 1,
    img: images.amenities07.image,
    alt: images.amenities07.alt,
    title: "elevators",
  },
  {
    id: 2,
    img: images.amenities08.image,
    alt: images.amenities08.alt,
    title: "glass railing",
  },
  {
    id: 3,
    img: images.amenities09.image,
    alt: images.amenities09.alt,
    title: "grand entrance",
  },
  {
    id: 4,
    img: images.amenities04.image,
    alt: images.amenities04.alt,
    title: "Ev Charger",
  },
  {
    id: 5,
    img: images.amenities10.image,
    alt: images.amenities10.alt,
    title: "GYMNASIUM",
  },
  {
    id: 6,
    img: images.amenities11.image,
    alt: images.amenities11.alt,
    title: "landscape entrance",
  },
  {
    id: 7,
    img: images.amenities12.image,
    alt: images.amenities12.alt,
    title: "marble flooring",
  },
  {
    id: 8,
    img: images.amenities13.image,
    alt: images.amenities13.alt,
    title: "modular kitchen",
  },
  {
    id: 9,
    img: images.amenities14.image,
    alt: images.amenities14.alt,
    title: "office",
  },
  {
    id: 10,
    img: images.amenities15.image,
    alt: images.amenities15.alt,
    title: "parking system",
  },
  {
    id: 11,
    img: images.amenities16.image,
    alt: images.amenities16.alt,
    title: "rooftop lounge",
  },
  {
    id: 12,
    img: images.amenities06.image,
    alt: images.amenities06.alt,
    title: "Air Conditioning",
  },
  {
    id: 13,
    img: images.amenities17.image,
    alt: images.amenities17.alt,
    title: "security door phone",
  },
  {
    id: 14,
    img: images.amenities18.image,
    alt: images.amenities18.alt,
    title: "smart lights",
  },
  {
    id: 15,
    img: images.amenities19.image,
    alt: images.amenities19.alt,
    title: "windows",
  },
  {
    id: 16,
    img: images.amenities05.image,
    alt: images.amenities05.alt,
    title: "bathroom",
  },
];

export const virtualData = [
  {
    id: 1,
    img: images.virtual01.image,
    alt: images.virtual01.alt,
    title: "HILL VIEW WALKTHROUGH",
    link: "https://surbhi-infotech.s3.ap-south-1.amazonaws.com/Nagpal+Developers/Hill+View/campus/index.html",
  },
  {
    id: 2,
    img: images.virtual02.image,
    alt: images.virtual02.alt,
    title: "HILL VIEW 2 BHK",
    link: "https://www.surbhiinfotech.com/virtual-reality/realestate/portfolio/nagpal-developers/hill-view/2bhk/",
  },
  {
    id: 3,
    img: images.virtual03.image,
    alt: images.virtual03.alt,
    title: "HILL VIEW 3 BHK",
    link: "https://www.surbhiinfotech.com/virtual-reality/realestate/portfolio/nagpal-developers/hill-view/3bhk/",
  },
];

export const locationData = [
  {
    id: 1,
    img: images.connectivityIcon.image,
    alt: images.connectivityIcon.alt,
    title: "CONNECTIVITY",
    locations: [
      "Khar road railway station - 1.4 km",
      "Bandra railway station - 2.1 km",
      "Mumbai Airport - 5.3 km",
    ],
  },
  {
    id: 2,
    img: images.businessDistrictIcon.image,
    alt: images.businessDistrictIcon.alt,
    title: "BUSINESS DISTRICTS",
    locations: ["BKC - 6.8 km ", "Dadar - 8.7 km", "Andheri - 9.6 km"],
  },
  {
    id: 3,
    img: images.hospitalIcon.image,
    alt: images.hospitalIcon.alt,
    title: "HOSPITALS",
    locations: [
      "Holy Family Hospital - 1.8 km  ",
      "Lilavati Hospital - 3 km",
      "P.D. Hinduja Hospital - 650m",
    ],
  },
  {
    id: 4,
    img: images.collegesIcon.image,
    alt: images.collegesIcon.alt,
    title: "COLLEGES",
    locations: [
      "National College - 850 m",
      "Rizvi College - 1.5 km",
      "St. Andrew's College - 1.7 km",
    ],
  },
  {
    id: 5,
    img: images.schoolIcon.image,
    alt: images.schoolIcon.alt,
    title: "SCHOOLS",
    locations: [
      "St. Joseph School - 1.4 km",
      "Mount Mary School - 3.1 km",
      "St. Stanisiaus School - 1.5 km",
      "Podar International School - 2.7 km",
    ],
  },
  {
    id: 6,
    img: images.restaurantIcon.image,
    alt: images.restaurantIcon.alt,
    title: "RESTAURANTS",
    locations: [
      "Mini Punjab's Bandra - 190 m ",
      "Sequel - 210 m",
      "Tim Hortons - 300 m",
      "Smoke House Deli - 300 m",
    ],
  },
  {
    id: 7,
    img: images.entertainmentIcon.image,
    alt: images.entertainmentIcon.alt,
    title: "ENTERTAINMENT",
    locations: ["PVR Le Reve - 1.6 km", "Jio World Drive - 4.3 km"],
  },
];
