import React from "react";
import "./whatsapp.scss";
import { WhatsppIcon } from "../../images";

const Whatsapp = () => {
  return (
    <>
      <section className="whatsapp_sec1">
        <a
          href="https://wa.me/+919819925114"
          target="_blank"
          rel="noreferrer"
          className="whatsapp_link"
        >
          <img src={WhatsppIcon} alt="whatsapp icon" className="whatsapp_img" />
        </a>
      </section>
    </>
  );
};

export default Whatsapp;
