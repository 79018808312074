import React, { useState } from "react";
import "./EqnuireForm.scss";
import "./EqnuireForm_1.module.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formLogo } from "../../images";
import { useWindowSize } from "react-use";
import Joi from "joi";
import config from "../../config/config";
import helper from "../../helpers/helper";
import CommonCtaV2 from "../CommonCtaHillView/CommonCtaV2";
import http from "../../helpers/http";

const HillEqnuireForm = ({
  externalShow,
  externalHandleClose,
  formType,
  onBrochureSubmit,
}) => {
  const { width } = useWindowSize();
  const [internalShow, setInternalShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  console.log(externalShow);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const show = externalShow !== undefined ? externalShow : internalShow;

  const schema = Joi.object({
    name: Joi.string().trim().min(1).required().messages({
      "string.empty": "Name is required",
    }),
    email: Joi.string().email({ tlds: false }).required().messages({
      "string.empty": "Email is required",
      "string.email": "Please enter a valid email",
    }),
    phone: Joi.string()
      .pattern(/^\d{10}$/)
      .required()
      .messages({
        "string.empty": "Phone number is required",
        "string.pattern.base": "Phone number must be 10 digits",
      }),
  });

  const handleShow = () => setInternalShow(true);

  const handleClose = () => {
    if (externalHandleClose) {
      externalHandleClose();
    } else {
      setInternalShow(false);
    }
    setShowThankYou(false);
    setFormData({ name: "", email: "", phone: "" });
    setErrors({});
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));

    const fieldSchema = schema.extract(id);
    const { error } = fieldSchema.validate(value);

    setErrors((prev) => ({
      ...prev,
      [id]: error ? error.details[0].message : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { error } = schema.validate(formData, { abortEarly: false });

    if (error) {
      const validationErrors = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    if (formType && onBrochureSubmit) {
      await onBrochureSubmit({ ...formData, formType });
    } else {
      await http.post(`${config.api_url}/forms/enquire`, formData);
    }
    setShowThankYou(true);
    setFormData({ name: "", email: "", phone: "" });
    setErrors({});
    setTimeout(() => {
      handleClose();
    }, 3000);
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="enq_wrapper">
        <Button onClick={handleShow}>Request a Tour</Button>

        <Modal
          show={show}
          onHide={handleClose}
          className="enquire_form hill_view_main"
        >
          <Modal.Header closeButton>
            <Modal.Title>ENQUIRE NOW</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {width > 767 && (
              <img src={formLogo} alt="nagpal logo" className="bg_logo" />
            )}
            <form className="form_wrapper" onSubmit={handleSubmit}>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control input_field"
                  id="name"
                  placeholder="Name*"
                  value={formData.name}
                  onChange={handleChange}
                />
                <label for="name">Name*</label>
                {errors.name && (
                  <p className="error hilView_error_form_msg">{errors.name}</p>
                )}
              </div>
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control input_field"
                  id="email"
                  placeholder="Email*"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label for="name">Email*</label>
                {errors.email && (
                  <p className="error hilView_error_form_msg">{errors.email}</p>
                )}
              </div>
              <div className="form-floating">
                <input
                  type="tel"
                  className="form-control input_field"
                  id="phone"
                  placeholder="Phone No.*"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <label for="name">Phone No.*</label>
                {errors.phone && (
                  <p className="error hilView_error_form_msg">{errors.phone}</p>
                )}
              </div>
              {errors.submit && (
                <p className="error hilView_error_form_msg text-center">
                  {errors.submit}
                </p>
              )}
              <CommonCtaV2
                linkText={isSubmitting ? "Submitting..." : "Submit"}
              />
              {showThankYou && (
                <p className="thank_msg">Thank you for your interest</p>
              )}{" "}
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default HillEqnuireForm;
