import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ctaArrow, ctaArrowWhite, ctaMobHill } from "../../images";
import "./commonCtaV2.scss";
import { useWindowSize } from "react-use";

const CommonCtaV2 = ({
  link,
  linkText,
  linkCta,
  externalLink,
  sameTab,
  isDownolad,
}) => {
  const [isHover, setIsHover] = useState(false);
  const { width } = useWindowSize();
  return (
    <section className="comm_cta_sec1">
      {linkCta ? (
        externalLink ? (
          <a
            href={link}
            target={sameTab ? "_self" : "_blank"}
            rel="noreferrer"
            className={`cta_link ${isHover ? "active" : ""}`}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
            {...(isDownolad ? { download: true } : {})}
          >
            <span className="cta_text">{linkText}</span>{" "}
            {/* <img
              src={isHover ? ctaArrowWhite : ctaArrow}
              alt="right arrow"
              className="cta_arrow"
            /> */}
              {width >= 767 && (
              <img
                src={isHover ? ctaArrowWhite : ctaArrow}
                alt="right arrow"
                className="cta_arrow"
              />
            )}
            {width <= 766 && (
              <img
                src={isHover ? ctaArrowWhite : ctaMobHill}
                alt="right arrow"
                className="cta_arrow"
              />
            )}
          </a>
        ) : (
          <Link
            to={link}
            className={`cta_link ${isHover ? "active" : ""}`}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
          >
            <span className="cta_text">{linkText}</span>{" "}
            {/* <img
              src={isHover ? ctaArrowWhite : ctaArrow}
              alt="right arrow"
              className="cta_arrow"
            /> */}
            {width >= 767 && (
              <img
                src={isHover ? ctaArrowWhite : ctaArrow}
                alt="right arrow"
                className="cta_arrow"
              />
            )}
            {width <= 766 && (
              <img
                src={isHover ? ctaArrowWhite : ctaMobHill}
                alt="right arrow"
                className="cta_arrow"
              />
            )}
          </Link>
        )
      ) : (
        <button
          className={`cta_link ${isHover ? "active" : ""}`}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        >
          <span className="cta_text">{linkText}</span> {/* */}
          {width >= 767 && (
            <img
              src={isHover ? ctaArrowWhite : ctaArrow}
              alt="right arrow"
              className="cta_arrow"
            />
          )}
          {width <= 766 && (
            <img
              src={isHover ? ctaArrowWhite : ctaMobHill}
              alt="right arrow"
              className="cta_arrow"
            />
          )}
        </button>
      )}
    </section>
  );
};

export default CommonCtaV2;
