import React from "react";
import "./virtualWalkthrough.scss";
import CommonCtaV2 from '../CommonCtaHillView/CommonCtaV2'
import { hillViewLogo, icon360 } from "../../images";

const VirtualWalkthrough = ({ walkthroughData }) => {
  return (
    <>
      <section className="walkthrough_container">
        <div className="my_container_2">
          <div className="row walkthrough_row">
            {walkthroughData.map((item, i) => (
              <div className="col-lg-4 col-md-6 walkthrough_col" key={i}>
                <div className="walkthrough_box">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="walkthrough_link"
                  >
                    <img
                      src={item.img}
                      alt={item.alt}
                      className="walkthrough_img"
                    />
                    <img
                      src={hillViewLogo}
                      alt="hill view logo"
                      className="hill_logo"
                    />
                    <div className="detail_box">
                      <img src={icon360} alt="360 icon" className="icon360" />
                      <p className="section_desc">
                        Click to get a virtual tour
                      </p>
                    </div>
                  </a>
                  <h4 className="section_subheading24">{item.title}</h4>
                  <CommonCtaV2
                    linkCta={true}
                    externalLink={true}
                    linkText="Virtual Tour"
                    link={item.link}
                  />

                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default VirtualWalkthrough;
