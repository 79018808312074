import _ from "lodash";
import {
  images,
  projectlocation,
  residenceicon,
  nntowerpdf,
  areaicon,
  propertytypeicon,
  yearscompletion,
  leReveBrochour,
  satnamleelabrochur,
  bhagirathipdf,
  hirapdf,
} from "../images";

export const datas = [
  {
    id: 1,
    isFeatured: 1,
    featured_image: images.completedprojectHillview,

    title: "Hill View",
    slug: "hill-view",
    status: "ongoing",
    thumbnail: images.completedprojectHillview,
    // location: "Juhu",
    location: "16th ROAD, BANDRA (W)",
    description:
      "Having a strong key-hold in Mumbai's Luxury suburban region, we've grown to revolutionize the real estate industry. Ethics, Experience, Entrepreneurship & Expertise are the 4 E's with which we've made our company grow by leaps and bounds. Having a strong key-hold in Mumbai's Luxury suburban region, we've grown to revolutionize the real estate industry.",
    // brochure: "",
    // image: images.completedproject5thavenue,
    // circle_text: images.completed.image,
    // subNav: [
    //   {
    //     _id: "one",
    //     tabName: "OVERVIEW",
    //   },
    //   {
    //     _id: "two",
    //     tabName: "PROJECT GALLERY",
    //   },
    //   // {
    //   //   _id: "three",
    //   //   tabName: "AMENITIES",
    //   // },
    //   {
    //     _id: "four",
    //     tabName: "NEARBY LOCATIONS",
    //   },
    //   // {
    //   //   _id: "five",
    //   //   tabName: "FLOOR PLANS",
    //   // },
    // ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Juhu",
        title: "Location : Bandra West, Mumbai",
      },
      {
        icon: areaicon,
        title: "Plot Area : 745 sq.ft.",
      },
      {
        icon: residenceicon,
        title: "Residences : 2 BHK, 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    // galleries: [
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery1,
    //   //   caption: "5th Avenue Apartment",
    //   //   // embed_code: "",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery2,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery3,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery4,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery5,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery6,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery7,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery8,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    //   // {
    //   //   type: "image",
    //   //   thumbnail: images.fifthavenuegallery9,
    //   //   caption: "5th Avenue Apartment",
    //   // },
    // ],
    // // amenities_title: "AMENITIES",
    // // amenities_desc:
    // // "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    // amenities: [
    //   //   {
    //   //     icon: projectlocation,
    //   //     title: "",
    //   //     description: "",
    //   //     image: images.project_amenities_1,
    //   //   },
    //   //   {
    //   //     icon: residenceicon,
    //   //     title: "",
    //   //     description: "",
    //   //     image: images.project_amenities_2,
    //   //   },
    //   //   {
    //   //     icon: projectlocation,
    //   //     title: "",
    //   //     description: "",
    //   //     image: images.project_amenities_1,
    //   //   },
    //   //   {
    //   //     icon: residenceicon,
    //   //     title: "",
    //   //     description: "",
    //   //     image: images.project_amenities_2,
    //   //   },
    // ],
    // map_link:
    //   // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60333.73296848016!2d72.8134666!3d19.0699663!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9716203c66b%3A0x31907f94a754d3f9!2sFifth%20Avenue%20Apartment!5e0!3m2!1sen!2sin!4v1679322065990!5m2!1sen!2sin",
    //   "https://maps.google.com/maps?q=Fifth%20Avenue%20Apartment%204R5M+2H9,%20N%20S%20Rd%20Number%205,%20Friends%20Society,%20JVPD%20Scheme,%20Vile%20Parle%20West,%20Mumbai,%20Maharashtra%20400056&t=&z=19&ie=UTF8&iwloc=&output=embed",
    // locations: [
    //   {
    //     title: "CONNECTIVITY",
    //     description:
    //       "<p>SV Road - 1.0 KM<p/><p>Western express highway - 3.0 KM<p/><p>Bandra Worli Sea Link - 9.0 KM<p/>",
    //     // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
    //   },
    //   {
    //     title: "MALLS & SUPERMARKET",
    //     description:
    //       "<p>Alfa Stores & Supermarket - 1.0 KM<p/><p>PVR Dynamix Mall Juhu  - 1.0 KM<p/><p>Shoppers Stop - 2.0 KM<p/>",
    //     // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
    //   },
    //   {
    //     title: "Health Care",
    //     description:
    //       "<p>Nanavati Max Super Speciality Hospital - 2.0 KM<p/><p>Cooper Hospital - 0.2 KM<p/>",
    //     // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
    //   },
    //   {
    //     title: "",
    //     description: "",
    //     // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
    //   },
    // ],
    // area_details: [
    //   // {
    //   //   title: "Plot Area",
    //   //   area: " Sq. Ft.",
    //   // },
    //   // {
    //   //   title: "Villa Area",
    //   //   area: " Sq. Ft.",
    //   // },
    // ],
    // floor_plans: [
    //   // {
    //   //   title: "Ground Floor",
    //   //   thumbnail: images.project_floor_1,
    //   //   file: images.project_floor_1.image,
    //   // },
    //   // {
    //   //   title: "First Floor",
    //   //   thumbnail: images.project_floor_2,
    //   //   file: images.project_floor_2.image,
    //   // },
    // ],
    // virtualTour: [],
  },
  {
    id: 2,
    isFeatured: 1,
    featured_image: images.lerevefeatured,

    // title: "LE RÊVE",
    title: "Le Réve",
    slug: "le-reve",
    status: "ongoing",
    thumbnail: images.ongoingproject6,
    location: "15TH ROAD, BANDRA (W)",
    description:
      "Situated in the quiet and residential lane of 15th Road, Bandra (W), this building boasts a neo-classical elevation. The name of this building arrived from the French language, which means 'The Dream'. A boutique 10-storey building with multiple amenities is what makes it stand out from other buildings in the vicinity. The old building was known as 'Siddharth'.",
    brochure: leReveBrochour,
    image: images.ongoingproject6,
    circle_text: images.RERAnoP51800050072.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      {
        _id: "three",
        tabName: "AMENITIES",
      },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
      {
        _id: "six",
        tabName: "VIRTUAL TOUR",
      },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : Bandra West, Mumbai",
      },
      {
        icon: areaicon,
        title: "Area : 663 sq. yards",
      },
      {
        icon: residenceicon,
        title: "Residences : 1 BHK, 2 BHK, 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.lerevegallery3,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery2,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery1,
        caption: "le réve",
        // embed_code: "",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery5,
        caption: "le réve",
      },

      {
        type: "image",
        thumbnail: images.lerevegallery7,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery8,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery9,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery10,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery11,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery12,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery13,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery6,
        caption: "le réve",
      },
      {
        type: "image",
        thumbnail: images.lerevegallery4,
        caption: "le réve",
      },
    ],
    amenities_title: "AMENITIES",
    amenities_desc: "",
    amenities: [
      {
        icon: projectlocation,
        title: "Gymnasium",
        description:
          "A fully equipped Gymnasium with the best equipment and machinery to cater to the needs of all age groups. This gymnasium comes with a Steam room and shower area.",
        image: images.lerevegymnasium,
      },
      {
        icon: residenceicon,
        title: "Society Office",
        description:
          "A society office in order to manage the day-to-day affairs of the co-operative housing society.",
        image: images.lereveSocietyOffice,
      },
      {
        icon: projectlocation,
        title: "Rooftop Lounge",
        description:
          "A rooftop lounge of approximately 1,400 sq. ft. to enjoy the mesmerizing views. This rooftop lounge comes with a multi purpose turf area to enjoy a yoga workout or host a function of 60+ guests. It also comes with a Gazebo to enjoy a quaint and peaceful evening.",
        image: images.Rooftoplounge,
      },
      {
        icon: residenceicon,
        title: "Roofotp lounge Gazebo",
        description:
          "A gazebo on the rooftop lounge for you to enjoy an evening with yourself or host a small gathering. A Well-lit area with mood lighting and outdoor seating for up to 10 people allows you to make the most of it.",
        image: images.Rooftoploungegazebo,
      },
      {
        icon: projectlocation,
        title: "Rooftop lounge deck",
        description:
          "A multi purpose turf with seating on either side of it allows you to enjoy your evening yoga session or take a walk and inhale the fresh air. An outdoor bar along with platforms makes it ideal for a get together of friends and family.",
        image: images.lereveRooftoploungedeck,
      },
      {
        icon: residenceicon,
        title: "Lobby",
        description:
          "A well designed double height lobby with a reception table and waiting area.",
        image: images.lerevelobby,
      },
    ],
    map_link:
      "https://maps.google.com/maps?q=Siddharth%20Building%2015th%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400050&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>Linking Road – 0.6 KM </p><p>Bandra Worli Sea Link – 3.9  KM </p><p>Sv Road – 3.7  KM </p><p>Bkc – 4.2  KM</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Jio World Drive – 4.7 KM </p><p>Phoenix Palladium Mall – 9.5 KM </p><p>Foodhall, Linking Road – 1.5 KM</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>Pd Hinduja Hospital – 0.8 KM </p><p>Holy Family Hospital – 1.5 KM </p><p>Agarwal Nursing Home – 0.25 KM </p><p>Welness Forever, Pali Hill – 0.5 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana – 0.9 KM </p><p>Otters Club – 1.5 KM </p><p>Eat Around the Corner – 0.3 KM </p><p>Taj Lands End Hotel – 3.0  KM </p><p>Starbucks Coffee, 24th Road – 0.25  Km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: "Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: "Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "1bhk le-réve 3",
      //   thumbnail: images.lerevefloor1,
      //   file: images.lerevefloor1.image,
      // },
      // {
      //   title: "2 3bhk le réve 3",
      //   thumbnail: images.lerevefloor2,
      //   file: images.lerevefloor2.image,
      // },
      // {
      //   title: "10th floor penthouse le réve 3",
      //   thumbnail: images.lerevefloor3,
      //   file: images.lerevefloor3.image,
      // },
    ],
    virtualTour: [
      {
        title: "2 BHK",
        thumbnail: images.virtual_1.image,
        link: "https://youtu.be/rf3IzrrF1dY",
      },
      {
        title: "3 BHK",
        thumbnail: images.virtual_2.image,
        link: "https://youtu.be/1aYSMzWD3CE",
      },
    ],
  },
  {
    id: 3,
    isFeatured: 0,
    featured_image: images.satnamleelafeatured,

    title: "Satnam Leela",
    slug: "satnam-leela",
    status: "completed",
    thumbnail: images.satnamleela,
    location: "Sindhi Society, Chembur, Mumbai",
    description:
      "At a height of 11 storeys, ‘Satnam Leela’ is an iconic residential tower featuring 2BHK & 3BHK options. The building's unique elevation and façade design are what make it stand out from the other buildings in the area. Crafted to perfection, this building is simply going one-of-its-kind in the Sindhi Society neighborhood overlooking the Sindhi Gymkhana.",
    brochure: satnamleelabrochur,
    image: images.satnamleela,
    circle_text: images.RERAnoP51900045357.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      {
        _id: "three",
        tabName: "AMENITIES",
      },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : Sindhi Society, Chembur, Mumbai",
      },
      {
        icon: areaicon,
        title: "Plot Area : 605 sq. yards",
      },
      {
        icon: residenceicon,
        title: "Residences : 2 BHK & 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.satnamleelagallery10,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery11,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery7,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery8,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery9,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery5,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery6,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery2,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery3,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery4,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery12,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery13,
        caption: "satnam leela",
      },
      {
        type: "image",
        thumbnail: images.satnamleelagallery1,
        caption: "satnam leela",
      },

      // {
      //   type: "video",
      //   thumbnail: images.project_gallery_2,
      //   caption: "demo caption 2",

      //   embed_code: "",
      // },
    ],
    amenities_title: "AMENITIES",
    amenities_desc: "",
    // "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      // {
      //   icon: projectlocation,
      //    title: "Rooftop lounge with jacuzzi",
      //   description:
      //     "The rooftop terrace of this building boasts of a jacuzzi with a pergola on top of it. There’s also a yoga deck with ample amount of outdoor seating for a get-together of your near & dear ones.",
      //   image: images.project_amenities_1,
      // },
      {
        icon: projectlocation,
        title: "Rooftop Lounge",
        description:
          "A rooftop lounge of approximately 1,000 sq. ft. to enjoy the mesmerizing views of Chembur. This rooftop lounge comes with a multi purpose turf area to enjoy a yoga workout or host a function of 60+ guests. It also comes with a Gazebo to enjoy a quaint and peaceful evening. With ample amount of seating and a platform, this rooftop lounge is ideal to host the perfect get-together or enjoy a quaint peaceful evening with yourself.",
        image: images.satnamleelaamenities1,
      },
      {
        icon: residenceicon,
        title: "Lobby",
        description:
          "A well designed double height lobby with a reception table and waiting area. This grand lobby is road facing and comes with superior quality marble flooring and multiple air conditioners.",
        image: images.satnamleelaamenities2,
      },
      // {
      //   icon: residenceicon,
      //   title: "Gymnasium",
      //   description:
      //     "A gymnasium that fulfills all age categories. Equipped with the best machineries A steam room is also there to help you in your rest & recovery.",
      //   image: images.project_amenities_2,
      // },
      // {
      //   icon: projectlocation,
      //   title: "Society office",
      //   description:
      //     "To handle the society’s day-to-day activities, there is a separate room with ample amount of space to hold meetings for all members of the building.",
      //   image: images.project_amenities_1,
      // },
      // {
      //   icon: residenceicon,
      //   title: "EV parking",
      //   description:
      //     "2 EV charging ports in the building for charging your electric vehicle.",
      //   image: images.project_amenities_2,
      // },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3771.2867853731645!2d72.889118!3d19.0511245!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8af9b639b1d%3A0x7ad5cc2220342283!2sLeela%20Nivas!5e0!3m2!1sen!2sin!4v1669907239816!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Leela%20Nivas%20Plot%20No%2086,Shiv%20Puri,Chembur%20Naka,%20Sindhi%20Society,%20Kurla,%20Mumbai,%20Maharashtra%20400071&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>Santacruz Chembur Link Road – 2.0 KM </p><p>Bkc – 4.0 KM </p><p>Eastern Express Highway – 4.0 KM</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Jio World Drive – 5.3 KM </p><p>Cubic Mall, Chembur – 2.0 KM </p><p>Sahakari Bhandar – 2.0 KM</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.0 KM </p><p>OMA WOMENS HOSPITAL – 2.0 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Sindhi Society Gymkhana – 0.02 KM </p><p>The Acres Club – 0.3 KM </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 KM </p><p>Movie Time Cinemas – 2.2 KM </p><p>Wework, Bkc- 4.00 KM </p><p>Chembur Social – 2.5 KM</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: "4245 Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: "7780 Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 4,
    isFeatured: 0,
    featured_image: images.nntowerfeatured,

    title: "NN Tower",
    slug: "nn-tower",
    status: "completed",
    thumbnail: images.ongoingproject3,
    location: "13TH ROAD, KHAR (W)",
    description:
      'NN Tower is a luxurious 9-storey building located in the heart of the bustling Khar (West) neighborhood. <span class="span_desc">Looking over a clear front open space, this building\'s floor planning boasts of well-planned 1/2/3 BHKs. Strategically located close to Linking Road, Hinduja Hospital, and many other public and private facilities.</span>',
    brochure: nntowerpdf,
    image: images.ongoingproject3,
    circle_text: images.RERAnoP51800033235.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      {
        _id: "three",
        tabName: "AMENITIES",
      },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : Khar, West",
      },
      {
        icon: areaicon,
        title: "Area : 575 sq. yards",
      },
      {
        icon: residenceicon,
        title: "Yards Apartments : 1 BHk, 2 BHk, 3 BHk",
      },
      {
        icon: yearscompletion,
        title: "Office Spaces : 2 Units",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Mixed Use (Residential & Office Space)",
      },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.nntowergallery3,
        caption: "NN Tower",
        // embed_code: "",
      },
      {
        type: "image",
        thumbnail: images.nntowergallery2,
        caption: "NN Tower",
        // embed_code: "",
      },
      {
        type: "image",
        thumbnail: images.nntowergallery1,
        caption: "NN Tower",
        // embed_code: "",
      },
    ],
    amenities_title: "AMENITIES",
    amenities_desc: "",
    amenities: [
      // {
      //   icon: projectlocation,
      //   title: "ROOFTOP LOUNGE WITH JACUZZI",
      //   description:
      //     "The rooftop terrace of this building boasts of a Jacuzzi with a pergola on top of it. There’s also a yoga deck with ample amount of outdoor seating for a get-together of your near & dear ones.",
      //   image: images.galleryplaceholder,
      // },
      // {
      //   icon: projectlocation,
      //   title: "Society office",
      //   description:
      //     "To handle the society’s day-to-day activities, there is a separate room with ample amount of space to hold meetings for all members of the building.",
      //   image: images.galleryplaceholder,
      // },
      // {
      //   icon: projectlocation,
      //   title: "EV parking",
      //   description:
      //     "2 EV charging ports in the building for charging your electric vehicle.",
      //   image: images.galleryplaceholder,
      // },
      {
        icon: projectlocation,
        title: "Gymnasium",
        description:
          "A fully equipped Gymnasium with the best equipment and machinery to cater to the needs of all age groups. This gymnasium comes with a Steam room and shower area.",
        image: images.nntowerGymnasiumSteam,
      },
      {
        icon: residenceicon,
        title: "Society Office/Games Room",
        description:
          "A society office in order to manage the day-to-day affairs of the co-operative housing society. Also boasts of a Table tennis table to enjoy a quick workout.",
        image: images.nntowerGamesroom,
      },
      {
        icon: projectlocation,
        title: "Rooftop Lounge",
        description:
          "A rooftop lounge of approximately 1,400 sq. ft. to enjoy the mesmerizing views. This rooftop lounge comes with a multi purpose turf area to enjoy a yoga workout or host a function of 60+ guests. It also comes with a Jacuzzi to enjoy a quaint and peaceful evening. With ample amount of seating, a platform and an outdoor bar, this rooftop lounge is ideal to host the perfect get-together or enjoy a quaint peaceful evening with yourself.",
        image: images.nntowerRooftoplounge,
      },
      {
        icon: residenceicon,
        title: "Lobby",
        description:
          "A well designed double height lobby with a reception table and waiting area.",
        image: images.nntowerlobby,
      },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9360979345693!2d72.8352519!3d19.066547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c97e1d93f417%3A0x7f5a7d0b91336e04!2sNarayan%20Niwas%20-%20NN%20TOWER!5e0!3m2!1sen!2sin!4v1669903371061!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Narayan%20Niwas%20-%20NN%20TOWER%2013th%20Rd,%20Khar,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>Linking Road – 0.30 KM </p><p>Sv Road – 0.45 M </p><p>Western Express Highway – 2.80 KM </p><p>Bandra Worli Sea Link – 4.20 KM</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall – 0.6 KM </p><p>Vijay Sales (Electronics) – 0.45 KM </p><p>Globus Mall – 1.6 KM </p><p>Foodhall – 1.0 KM </p><p>Neelam Foodland – 1.20 KM</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>Pd Hinduja Hospital, Khar – 0.25 KM </p><p>Beams Multispecialty Hospital – 1.3 KM </p><p>Mahavir Hospital – 1.0 KM </p><p>Welness Medical – 1.1 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Mcdonalds – 0.2 KM </p><p>Pvr Le Réve – 1.6 KM </p><p>Khar Gymkhana – 0.75 KM </p><p>Radio Bar – 0.40 KM </p><p>National College – 1.0 KM </p><p>Podar International School, Khar – 0.80 KM</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: "Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: "Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "OFFICE SPACE – 2ND FLOOR",
      //   thumbnail: images.floorplaceholder,
      //   file: images.floorplaceholder.image,
      // },
      // {
      //   title: "1BHK – 6TH FLOOR",
      //   thumbnail: images.floorplaceholder,
      //   file: images.floorplaceholder.image,
      // },
      // {
      //   title: "2BHK – 8TH FLOOR",
      //   thumbnail: images.floorplaceholder,
      //   file: images.floorplaceholder.image,
      // },
      // {
      //   title: "3BHK – 7TH & 8TH FLOOR",
      //   thumbnail: images.floorplaceholder,
      //   file: images.floorplaceholder.image,
      // },
      // {
      //   title: "3BHK + TERRACE (PENTHOUSE)",
      //   thumbnail: images.floorplaceholder,
      //   file: images.floorplaceholder.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 5,
    isFeatured: 0,
    featured_image: images.hirafeatured,

    title: "Hira",
    slug: "hira",
    status: "completed",
    thumbnail: images.ongoingproject2,
    location: "14TH ROAD, KHAR (W)",
    description:
      "This boutique 10-storey building is in true definition a “Diamond”. Located close to Linking Road, this building is ideal for those who enjoy the hustle-bustle of Khar-Bandra. The project is executed by our sister concern company <b>L Nagpal Developers<b/>.",
    brochure: hirapdf,
    image: images.ongoingproject2,
    circle_text: images.RERAnoP51800031276.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      {
        _id: "three",
        tabName: "AMENITIES",
      },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      {
        _id: "five",
        tabName: "FLOOR PLANS",
      },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : 14th Road Khar West, Mumbai",
      },
      {
        icon: areaicon,
        title: "Plot Area : 630 sq. yards",
      },
      {
        icon: residenceicon,
        title: "Residences : 2 BHK & 2.5 BHK (Jodi Possible – 4/5  BHK)",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.galleryplaceholder,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "image",
      //     thumbnail: images.galleryplaceholder,
      //     caption: "demo caption 2",
      //     // embed_code: "",
      //   },
    ],
    amenities_title: "Amenities",
    amenities_desc: "",
    amenities: [
      {
        icon: projectlocation,
        title: "Gymnasium",
        description:
          "A fully equipped Gymnasium with the best equipment and machinery to cater to the needs of all age groups",
        image: images.bhagirathiamenity1,
      },
      {
        icon: projectlocation,
        title: "Society Office/Games Room",
        description:
          // "A society office in order to manage the day-to-day affairs of the co-operative housing society.",
          "A society office in order to manage the day-to-day affairs of the co-operative housing society. Also boasts of a Table tennis table to enjoy a quick workout.",

        image: images.nntowerGamesroom,
      },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.790708551867!2d72.8329467!3d19.0729374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90c3a99347b%3A0x4cf7e82ae280a517!2sHira%20building!5e0!3m2!1sen!2sin!4v1669897053432!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Hira building 3RFM+55G, 14th Rd, Khar West, Mumbai, Maharashtra 400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>Linking Road – 0.1 KM</p><p>Sv Road – 0.5 KM </p><p>Bandra – Worli Sea Link – 4.5 KM </p><p>Western Express Hgihway – 2.1 KM</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Jio World Drive – 4.6 KM </p><p>Globus Mall – 2.0  KM </p><p>Foodhall – 0.45  KM </p><p>Neelam Foodland – 0.4 KM</p>",
      },
      {
        title: "Healt Care",
        description:
          "<p>Pd Hinduja Hospital – 0.8 KM </p><p>Mahavir Hospital – 0.9 KM </p><p>Happy Tails Veterinary Clinic – 0.03 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana – 0.4 KM </p><p>Ministyr of Crab, India – 0.04 KM </p><p>Starbucks – 0.5 KM </p><p>Dominos Pizza, Khar – 0.5 KM</p>",
      },
    ],
    area_details: [
      {
        title: " 7th & 9th Floor",
        area: "<ul><li>Roadside facing - 2.5 BHK <br />(East) - 850 sq. ft.</li><li>Backside facing - 2 BHK <br />(West) - 750 sq. ft.</li></ul>",
      },
      {
        title: " 8th Floor",
        area: "<ul><li>Roadside facing - 2 BHK <br />(East) - 800 sq. ft.</li><li>Backside facing - 2 BHK <br />(West) - 800 sq. ft.</li></ul>",
      },
      // {
      //   title: " 10th Floor",
      //   area: "<ul><li>Carpet Area - 1436.75 sq. ft.</li><li>Rera Carpet Area - 1508.00 <br />sq. ft.</li><li>Terrace Area (50%) - 42.00 <br />sq. ft.</li></ul>",
      // },
    ],
    floor_plans: [
      {
        title: "7<sup>th</sup> & 9<sup>th</sup> FLOOR",
        thumbnail: images.hiraplan1,
        file: images.hirafloorplan1.image,
      },
      // {
      //   title: "10<sup>th</sup> FLOOR",
      //   thumbnail: images.hiraplan2,
      //   file: images.hirafloorplan2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 6,
    isFeatured: 1,
    featured_image: images.bhagirathifeatured,
    title: "Bhagirathi Sadan",
    slug: "bhagirathi-sadan",
    status: "ongoing",
    thumbnail: images.ongoingproject1,
    location: "17TH ROAD, KHAR (W)",
    description:
      "Situated on one of the broadest roads in Khar West, Some parts of this building enjoy a magnificent & breathtaking sea view. The 14-storey tower boasts of its extravagant façade view and spacious 2.5,3 & 4 BHKs. A large size gymnasium and an outdoor party terrace redefine the luxury that this building provides. Located on 17th Road, Khar (W), this building has ample amount of open spaces and a covered parking tower. The project is executed by our sister concern company <b>L Nagpal Developers<b/>.",
    brochure: bhagirathipdf,
    image: images.ongoingproject1,
    circle_text: images.RERAnoP51800048002.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      {
        _id: "three",
        tabName: "AMENITIES",
      },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      {
        _id: "five",
        tabName: "FLOOR PLANS",
      },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : Khar West",
      },
      {
        icon: areaicon,
        title: "Plot Area : 1000 sq. yards",
      },
      {
        icon: residenceicon,
        title: "Residences : 2.5 BHK, 3 BHK, 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential",
      },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.bhagirathigallery1,
        caption: "Bhagirathi Sadan Day",
      },
      {
        type: "image",
        thumbnail: images.bhagirathigallery2,
        caption: "Bhagirathi Sadan Night",
      },
    ],
    amenities_title: "AMENITIES",
    amenities_desc: "",
    amenities: [
      {
        icon: projectlocation,
        title: "Gymnasium",
        description:
          "A fully equipped Gymnasium with the best equipment and machinery to cater to the needs of all age groups",
        image: images.bhagirathiamenity1,
      },
      {
        icon: projectlocation,
        title: "Society Office/Games Room",
        description:
          "A society office in order to manage the day-to-day affairs of the co-operative housing society. Also boasts of a Table tennis table to enjoy a quick workout.",
        image: images.nntowerGamesroom,
      },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3770.756991373306!2d72.8303439!3d19.0744191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90c847b483b%3A0xe3d79e8027de6a60!2sBhagirathi%20Sadan%20By%20L%20Nagpal%20Developers!5e0!3m2!1sen!2sin!4v1673962769377!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=%27Bhagirathi%20Sadan%27%20By%20L%20Nagpal%20Developers%20Bhagirathi%20Sadan,%20517,%2017th%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "Connectivity",
        description:
          "<p>Linking Road – 0.8 KM </p><p>SV Road – 1.2 KM </p><p>Western Express Highway – 2.90 KM</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall – 1.3 KM </p><p>Neelam Foodland – 0.1 KM </p><p>Foodhall – 1.0 KM</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar – 1.3 KM </p><p>Womens Hospital Khar – 0.65 KM </p><p>Lilavati Hospital and Research Center – 3.60 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar gymkhana – 0.5 KM </p><p>Otters Club – 2.50 KM </p><p>Starbucks, RedChillies Entertainment, Khar – 0.35 KM </p><p>Olive Bar and Kitchen – 1.0 KM</p>",
      },
    ],
    area_details: [
      // {
      //   title: "2.5 Bhk – 4th & 6th Floor West Facing",
      //   area: "850 Sq. Ft. Carpet Area",
      // },
      {
        title: "14th Floor",
        area: "<ul><li>Area of flat - 1966 sq. ft. </li><li> Exclusive Terrace - 350 Sq. ft.</li></ul>",
      },
      {
        title: "11th - 13th Floor",
        // area: "<ul><li>West facing - 1160 sq. ft</li><li>Exclusive Terrace - 350 Sq. ft</li></ul>",
        area: "<ul><li>West facing - 1160 sq. ft.</li><li>East facing - 1165 sq. ft.</li></ul>",
      },
    ],
    floor_plans: [
      {
        title: "14<sup>th</sup> FLOOR",
        thumbnail: images.bhagirathiplan1,
        file: images.bhagirathifloorplan1.image,
      },
      {
        title: "11<sup>th</sup> TO 13<sup>th</sup> FLOOR",
        thumbnail: images.bhagirathiplan2,
        file: images.bhagirathifloorplan2.image,
      },
    ],
    virtualTour: [],
  },
 
  {
    id: 7,
    isFeatured: 7,
    featured_image: images.galleryplaceholder,

    title: "5th Avenue Apartments",
    slug: "5th-avenue-apartments",
    status: "completed",
    thumbnail: images.completedproject5thavenue,
    // location: "Juhu",
    location: "5TH ROAD, J.V.P.D",
    description:
      "It was our first project in Juhu, so we decided to make a style statement in this area. This building boasts a triple-height lobby, automatic shuffle parking mechanism with a highly integrated security system, beautiful GRC work done on the façade, one-sided glass vision and a lot more. Situated on 5th Road Juhu, this is a lavish one offering spacious and fully ventilated 4 BHK.",
    brochure: "",
    image: images.completedproject5thavenue,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Juhu",
        title: "Location : 5TH ROAD, J.V.P.D",
      },
      {
        icon: areaicon,
        title: "Plot Area : 836 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.fifthavenuegallery1,
        caption: "5th Avenue Apartment",
        // embed_code: "",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery2,
        caption: "5th Avenue Apartment",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery3,
        caption: "5th Avenue Apartment",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery4,
        caption: "5th Avenue Apartment",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery5,
        caption: "5th Avenue Apartment",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery6,
        caption: "5th Avenue Apartment",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery7,
        caption: "5th Avenue Apartment",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery8,
        caption: "5th Avenue Apartment",
      },
      {
        type: "image",
        thumbnail: images.fifthavenuegallery9,
        caption: "5th Avenue Apartment",
      },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    // "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60333.73296848016!2d72.8134666!3d19.0699663!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9716203c66b%3A0x31907f94a754d3f9!2sFifth%20Avenue%20Apartment!5e0!3m2!1sen!2sin!4v1679322065990!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Fifth%20Avenue%20Apartment%204R5M+2H9,%20N%20S%20Rd%20Number%205,%20Friends%20Society,%20JVPD%20Scheme,%20Vile%20Parle%20West,%20Mumbai,%20Maharashtra%20400056&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 1.0 KM<p/><p>Western express highway - 3.0 KM<p/><p>Bandra Worli Sea Link - 9.0 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Alfa Stores & Supermarket - 1.0 KM<p/><p>PVR Dynamix Mall Juhu  - 1.0 KM<p/><p>Shoppers Stop - 2.0 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>Nanavati Max Super Speciality Hospital - 2.0 KM<p/><p>Cooper Hospital - 0.2 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Juhu gymkhana building - 0.9 KM<p/><p>Babubhai Jagjivandas Hall - 0.5 KM<p/><p>Jamnabai Narsee School - 0.5 KM<p/><p>Ecole Mondiale World School - 0.4 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 8,
    isFeatured: 8,
    featured_image: images.galleryplaceholder,

    title: "Jaswant Heights",
    slug: "jaswant-heights",
    status: "completed",
    thumbnail: images.completedprojectjaswant,
    location: "Khar 13th Road",
    description:
      "Khar 13th Road now has something extravagant to boast about! ‘JaswantHeights’, our 14-storey tower consists of Life houses – the houses which put life in your everyday life. With a beautiful entrance lobby with a GRC framed canopy, ample of parking on the stilt and podium level, a gymnasium and a recreational area, this building offers several amenities for the needs of all aged people. Situated at the corner of 13th and 8th Road in Khar (W), this tower is close to a temple, general stores, restaurants and Linking Road.",
    brochure: "",
    image: images.completedprojectjaswant,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Khar 13th Road",
        title: "Location : 13th Road, Khar (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 1015 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 3 & 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.jaswantgallerynew12,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew13,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew14,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew15,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew17,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew1,
        caption: "Jaswant Height",
        // embed_code: "",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew16,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew4,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew3,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew7,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew8,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew18,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew19,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew20,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew21,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew22,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew23,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew24,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew25,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew26,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew5,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew28,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew6,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew27,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew9,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew2,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew29,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew10,
        caption: "Jaswant Height",
      },
      {
        type: "image",
        thumbnail: images.jaswantgallerynew11,
        caption: "Jaswant Height",
      },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3770.933129369824!2d72.8350978!3d19.0666775!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9db4f5c69c3%3A0xe757471625fc6f33!2sJaswant%20heights!5e0!3m2!1sen!2sin!4v1673955502071!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Jaswant%20heights%20Jaswant%20Heights,%2013th%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.4 KM<p/><p>Western express highway - 2.0 KM<p/><p>Bandra Worli Sea Link - 3.0 KM<p/><p>Linking Road - 0.2 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.6 KM<p/><p>Neelam Foodland - 1.0 KM<p/><p>FoodHall - 1.0 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.2 KM<p/><p>Lilavati Hospital and Research Center - 2.0 KM<p/><p>Womens Hospital - 0.6 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.7 KM<p/><p>Otters Club - 2.0 KM<p/><p>Rao Saheb Patwardhan Park - 0.6 KM<p/><p>Bastian Restaurant & Bar - 0.4 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 9,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "Ruby",
    slug: "ruby",
    status: "completed",
    thumbnail: images.completedprojectruby,
    location: " 29th Road, Bandra (W)",
    description:
      "As precious as the stone, ‘Ruby’ is one of our Landmark projects. This 12-storey tower along with its double-height entrance lobby and shuffle car park system provides just the correct blend for stylish and high-quality living in Bandra. This uber-luxurious tower is situated at the junction of 29th and 23rd Road, Bandra (W).",
    brochure: "",
    image: images.completedprojectruby,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : 29th Road, Bandra (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 928 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 3 & 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.rubygallery29,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery30,
        caption: "Ruby",
      },

      {
        type: "image",
        thumbnail: images.rubygallery19,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery20,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery21,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery22,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery23,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery1,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery2,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery3,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery31,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery32,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery33,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery16,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery17,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery18,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery24,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery25,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery26,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery4,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery5,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery6,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery7,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery8,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery9,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery10,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery11,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery12,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery13,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery14,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery15,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery27,
        caption: "Ruby",
      },
      {
        type: "image",
        thumbnail: images.rubygallery28,
        caption: "Ruby",
      },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3771.0455117213587!2d72.8317029!3d19.0617365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c91444a01ccd%3A0x1fda85236f953a59!2sThe%20Ruby%20Apartments!5e0!3m2!1sen!2sin!4v1673955647337!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=The Ruby Apartments 52-A, 29th Rd, Bandra West, Mumbai, Maharashtra 400050&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.9 KM</p><p>Western express highway - 2.0 KM</p><p>Bandra Worli Sea Link - 3.0 KM</p><p>Linking Road - 0.5 KM</p>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.7 KM</p>Neelam Foodland - 1.6 KM<p></p><p>FoodHall - 2.1 KM</p>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 1.2 KM</p><p>Lilavati Hospital and Research Center - 1.7 KM</p><p>Womens Hospital - 1.1 KM</p>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 1.3 KM</p><p>Otters Club - 1.2 KM</p><p>Rao Saheb Patwardhan Park - 0.4 KM</p><p>Bastian Restaurant & Bar - 1.0 KM</p>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 10,
    isFeatured: "",
    featured_image: images.amiyafeatured,

    title: "Amiya",
    slug: "amiya",
    status: "completed",
    thumbnail: images.completedproject2,
    location: "18th Road Khar (W)",
    description:
      "AMIYA is a ‘delight’ to Mother Earth and its habitants. The earthy elevation along with the Green Building concept has made this building stand out on 18th Road Khar (W). This building has a great amount of open stilt and stack parking.",
    brochure: "",
    image: images.completedproject2,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : 18th Road Khar (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 750 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences :  2 & 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7521783288244!2d72.8291349!3d19.0746306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90ca0927849%3A0xbe867ba372aa474a!2sAmiya%2C%2018th%20Rd%2C%20Khar%20West%2C%20Mumbai%2C%20Maharashtra%20400052!5e0!3m2!1sen!2sin!4v1673955551664!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Amiya%2018th%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 1.0 KM<p/><p>Western express highway - 1.25 KM<p/>Bandra Worli Sea Link - 3.0 KM<p><p/><p>Linking Road - 0.5 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.6 KM<p/><p>Neelam Foodland - 0.2 KM<p/><p>FoodHall - 1.0 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 1.0 KM<p/><p>Lilavati Hospital and Research Center - 2.0 KM<p/><p>Womens Hospital - 0.6 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.6 KM<p/><p>Otters Club - 2.0 KM<p/><p>Rao Saheb Patwardhan Park - 1.6 KM<p/><p>Rajesh Khanna Garden - 0.45 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 11,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "Chhaya",
    slug: "chhaya",
    status: "completed",
    thumbnail: images.completedproject19,
    location: "15th Road, Khar (W)",
    description:
      "‘The house you looked at today and wanted to think about until tomorrow may be the same house that someone looked at yesterday and will buy today’ - Koki Adasi. <br /><br />Located in the heart of Khar (W), ‘Chhaya’ has set the benchmark for Deluxe Residential Flats. With an underground car park and an intimidating sea view from the higher floors and the terrace, this building will make you enjoy your afternoon nap, evening tea and night dinners with a surreal view!",
    brochure: "",
    image: images.completedproject19,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : 15th Road Khar (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 750 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 2 & 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3770.746024839199!2d72.8316539!3d19.074901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90c77faaaab%3A0x585c61e9aeb8de0f!2sChhaya%20building!5e0!3m2!1sen!2sin!4v1673955601354!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Chhaya%20building%203RFJ+XM6,%2015th%20Rd,%20opposite%20jain%20temple,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 1.0 KM</p><p>Western express highway - 2.4 KM</p><p>Bandra Worli Sea Link - 3.0 KM</p><p>Linking Road - 0.4 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 1.3 KM</p><p>Neelam Foodland - 0.45 KM</p><p>FoodHall - 0.45 KM</p>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 1.0 KM</p><p>Lilavati Hospital and Research Cente - 2.0 KM</p><p>Womens Hospital - 0.85 KM</p>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.45 KM</p><p>Otters Club - 2.6 KM</p><p>Rao Saheb Patwardhan Park - 1.6 KM</p><p>Starbucks Coffee - 0.2 KM</p>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 12,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "Vasant Vihar",
    slug: "vasant-vihar",
    status: "completed",
    thumbnail: images.completedprojectvasantvihar,
    location: "14TH ROAD, KHAR (W)",
    description:
      "Situated on the street parallel to Linking Road, Vasant Vihar has already become one of Khar 14th Road's signature buildings. The spacious 3 & 2BHK provide room for all family sizes. This building comes with an ample amount of stilt, covered and stack parking.",
    brochure: "",
    image: images.completedprojectvasantvihar,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Khar 14th Road",
        title: "Location : 14TH ROAD, KHAR (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 631 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 2 & 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3770.8629586324423!2d72.83302!3d19.069762!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c947855c93c9%3A0x1968606d1778b9a1!2sVasant%20Vihar!5e0!3m2!1sen!2sin!4v1673955700044!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Vasant%20Vihar%203R9M+W62,%2014th%20Rd,%20Khar,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.6 KM</p><p>Western express highway - 3.0 KM</p><p>Bandra Worli Sea Link - 4.0 KM</p><p>Linking Road - 0.3 KM</p>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.65 KM</p>Neelam Foodland - 1.0 KM<p></p><p>FoodHall - 1.4 KM</p>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.5 KM</p><p>Lilavati Hospital and Research Center - 2.7 KM</p><p>Womens Hospital - 0.55 KM</p>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.5 KM</p><p>Otters Club - 2.2 KM</p><p>Rao Saheb Patwardhan Park - 0.85 KM</p><p>Bastian Restaurant & Bar - 1.2 KM</p>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 13,
    isFeatured: "",
    featured_image: images.anupamafeatured,

    title: "Anupama",
    slug: "anupama",
    status: "completed",
    thumbnail: images.completedproject3,
    location: "14th Road, Khar (W)",
    description:
      "Located on 14th Road Khar (W), this building comes with wrap-around balconies and fully ventilated houses. A double-height entrance lobby and a lavish rooftop lounge are ideal to enjoy the views of the sea.",
    brochure: "",
    image: images.completedproject3,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : 14th Road Khar (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area :  650 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 3 & 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7779223989205!2d72.83309489999999!3d19.073499299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90c2386a2e1%3A0x32dffae585fc6fd9!2sAnupama%20Heights!5e0!3m2!1sen!2sin!4v1673955749605!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Anupama%20Heights%203RFM+96X,%2014th%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.6 KM<p/><p>Western express highway - 1.0 KM<p/><p>Bandra Worli Sea Link - 4.1 KM<p/><p>Linking Road - 0.2 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.65 KM<p/><p>Neelam Foodland - 0.7 KM<p/><p>FoodHall - 0.7 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.5 KM<p/><p>Lilavati Hospital and Research Center - 3.0 KM<p/><p>Womens Hospital - 0.55 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.5 KM<p/><p>Otters Club - 2.2 KM<p/><p>Rao Saheb Patwardhan Park - 0.85 KM<p/><p>Bastian Restaurant & Bar - 1.2 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 14,
    isFeatured: "",
    featured_image: images.bhagatfeatured,

    title: "Bhagat",
    slug: "bhagat",
    status: "completed",
    thumbnail: images.completedproject14,
    location: "14th Road, Bandra (W)",
    description:
      "Located on 14th Road Bandra (W), this building comes with wrap-around balconies and fully ventilated houses. A lavish road-facing entrance lobby and a lavish rooftop lounge are ideal to enjoy the views of the sea.",
    brochure: "",
    image: images.completedproject14,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Bandra (W)",
        title: "Location : 14TH ROAD, BANDRA (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 750 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 3 & 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.980483037363!2d72.83225085!3d19.064595699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c913936254cb%3A0x95348f1f7ede4bdc!2sBHAGAT%20HEIGHTS%2C%2014th%20Rd%2C%20Bandra%20West%2C%20Mumbai%2C%20Maharashtra%20400050!5e0!3m2!1sen!2sin!4v1673955791785!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=BHAGAT%20HEIGHTS%2014th%20Rd,%20Bandra%20West,%20Mumbai,%20Maharashtra%20400050&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.8 KM<p/><p>Western express highway - 3.0 KM<p/><p>Bandra Worli Sea Link - 4.1 KM<p/><p>Linking Road - 0.3 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.25 KM<p/><p>Neelam Foodland - 0.7 KM<p/><p>FoodHall - 1.1 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.5 KM<p/><p>Lilavati Hospital and Research Center - 3.0 KM<p/><p>Womens Hospital - 0.55 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.5 KM<p/><p>Otters Club - 2.2 KM<p/><p>Rao Saheb Patwardhan Park - 0.55 KM<p/><p>Bastian Restaurant & Bar - 0.7 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 15,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "La Vie",
    slug: "la-vie",
    status: "completed",
    thumbnail: images.completedprojectlaview,
    location: "14th Road, Khar (W)",
    description:
      "Located on 14th Road Khar (W), this building comes with wrap-around balconies and fully ventilated houses. A lavish entrance lobby and a lavish rooftop lounge are ideal to enjoy the views of the sea.",
    brochure: "",
    image: images.completedprojectlaview,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Khar (W)",
        title: "Location : 14TH ROAD, KHAR (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 750 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7814813503123!2d72.8329695!3d19.073342900000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90c2333529d%3A0xa9cf721262d123f5!2sLa-Vie%2C%2014th%20Rd%2C%20Khar%20West%2C%20Mumbai%2C%20Maharashtra%20400052!5e0!3m2!1sen!2sin!4v1673956191378!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=La-Vie%2014th%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.6 KM<p/><p>Western express highway - 1.0 KM<p/><p>Bandra Worli Sea Link - 4.1 KM<p/><p>Linking Road - 0.2 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.65 KM<p/><p>Neelam Foodland - 0.7 KM<p/><p>FoodHall - 0.7 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.5 KM<p/><p>Lilavati Hospital and Research Center - 3.0 KM<p/><p>Womens Hospital - 0.55 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.5 KM<p/><p>Otters Club - 2.2 KM<p/><p>Rao Saheb Patwardhan Park - 0.85 KM<p/><p>Bastian Restaurant & Bar - 1.2 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 16,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "PH House",
    slug: "ph-house",
    status: "completed",
    thumbnail: images.completedprojectphhouse,
    location: "14TH ROAD, KHAR (W)",
    description: "",
    brochure: "",
    image: images.completedprojectphhouse,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location :  14TH ROAD, KHAR (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 725 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.773553315315!2d72.833378!3d19.073691300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9dfe167d2d5%3A0x70b5877fab68b634!2sP.H%20House%20L.Nagpal%20Developers!5e0!3m2!1sen!2sin!4v1677757928010!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=P.H%20House%20L.Nagpal%20Developers%2014th%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.6 KM<p/><p>Western express highway - 1.0 KM<p/><p>Bandra Worli Sea Link - 4.1 KM<p/><p>Linking Road - 0.2 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.65 KM<p/><p>Neelam Foodland - 0.7 KM<p/><p>FoodHall - 0.7 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.5 KM<p/><p>Lilavati Hospital and Research Center - 3.0 KM<p/><p>Womens Hospital - 0.55 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.5 KM<p/><p>Otters Club - 2.2 KM<p/><p>Rao Saheb Patwardhan Park - 0.85 KM<p/><p>Bastian Restaurant & Bar - 1.2 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 17,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "Satnam Sharan",
    slug: "satnam-sharan",
    status: "completed",
    thumbnail: images.completedprojectsatnamsharan,
    location: "SINDHI SOCIETY, CHEMBUR",
    description:
      "Our first housing project is located in Chembur, Mumbai. In Satnam Sharan, the interiors are thoughtfully designed to provide adequate space, light and ventilation to the residential units. Chembur is well-connected to other parts of the city via an extensive road.",
    brochure: "",
    image: images.completedprojectsatnamsharan,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Chembur",
        title: "Location : SINDHI SOCIETY, CHEMBUR",
      },
      {
        icon: areaicon,
        title: "Plot Area :  510 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences :  2 & 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3771.288153706914!2d72.8889014!3d19.0510643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8af8e6ee1bf%3A0xac989bf244b1e6c8!2sSATNAM%20SHARAN!5e0!3m2!1sen!2sin!4v1673956298511!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=SATNAM%20SHARAN%20Satnam%20Sharabi-109,%20109,%20Waman%20Wadi%20Rd,%20Sindhi%20Society,%20Chembur,%20Mumbai,%20Maharashtra%20400071&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>Santacruz Chembur Link Road- 2.0 KM<p/><p>BKC - 4.0 KM<p/><p>Eastern Express Highway - 4.1 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Jio World Drive - 5.3 KM<p/><p>Cubic Mall, Chembur - 2.0 KM<p/><p>Sahakari Bhankdar- 2.0 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>Apollo Spectra Hospital - 3.3 KM<p/><p>Surana Sethia Hospital- 1.0 KM<p/><p>OMA Womens Hospital - 2.0 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Sindhi Society Gymkhana - 0.2 KM<p/><p>The Acres Club - 0.5 KM<p/><p>The Bombay Presidency Golf Club - 1.5 KM<p/><p>Chembur Social - 2.6 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 18,
    isFeatured: "",
    featured_image: images.sudhamaniwasfeatured,

    title: "Sudhama Niwas",
    slug: "sudhama-niwas",
    status: "completed",
    thumbnail: images.completedproject11,
    location: "16TH ROAD, KHAR (W)",
    description:
      "Sudhama Niwas is a completed premium residential property. The project boasts superior lifestyle amenities plus an easily accessible location and an array of thoughtfully designed bedrooms. Sudhama Niwas houses state-of-the-art Apartments that are sure to give you a superior lifestyle.",
    brochure: "",
    image: images.completedproject11,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        title: "Location : 16TH ROAD, KHAR (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 750 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 2 & 3 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7550138212955!2d72.8313129!3d19.074506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c911808dc4f3%3A0xdaa234900ace6e96!2sSudhama%20Niwas!5e0!3m2!1sen!2sin!4v1673956355862!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Sudhama%20Niwas%203RFJ+RG4,%2016th%20Cross%20Rd,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 1.0 KM<p/><p>Western express highway - 2.40 KM<p/><p>Bandra Worli Sea Link - 3.0 KM<p/><p>Linking Road - 0.4 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 1.3 KM<p/><p>Neelam Foodland - 0.45 KM<p/><p>FoodHall - 0.45 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 1.0 KM<p/><p>Lilavati Hospital and Research Center - 2.0 KM<p/><p>Womens Hospital - 0.85 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.45 KM<p/><p>Otters Club - 2.60 KM<p/><p>Rao Saheb Patwardhan Park - 1.6 KM<p/><p>Starbucks Coffee - 0.2 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 19,
    isFeatured: "",
    featured_image: images.uphaarfeatured,

    title: "Uphaar Mandir",
    slug: "uphaar-mandir",
    status: "completed",
    thumbnail: images.completedproject15,
    location: "10TH ROAD, KHAR (W)",
    description:
      "Situated in Khar West, this building is planned to offer a modern lifestyle to all the residents. Uphaar Mandir is one of the most sought-after projects in the city. It is located at 10th Road, Khar West, Mumbai, Maharashtra, India. , 400052.",
    brochure: "",
    image: images.completedproject15,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Khar West",
        title: "Location : 10th Road, Khar (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 725 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 3 & 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.78430300536!2d72.8368468!3d19.0732189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90f033303a5%3A0x508444c1ed9fe88b!2sUphar%2C%2010th%20Rd%2C%20Khar%2C%20Khar%20West%2C%20Mumbai%2C%20Maharashtra%20400052!5e0!3m2!1sen!2sin!4v1673956400731!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Uphar%2010th%20Rd,%20Khar,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.1 KM<p/><p>Western express highway - 2.0 KM<p/><p>Bandra Worli Sea Link - 4.0 KM<p/><p>Linking Road - 0.5 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.6 KM<p/><p>Neelam Foodland - 1.0 KM<p/><p>FoodHall - 1.0 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.4 KM<p/><p>Lilavati Hospital and Research Center - 2.5 KM<p/><p>Womens Hospital - 1.0 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.7 KM<p/><p>Otters Club - 2.50 KM<p/><p>Rao Saheb Patwardhan Park - 1.0 KM<p/><p>Bastian Restaurant & Bar - 1.0 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  {
    id: 20,
    isFeatured: "",
    featured_image: images.zahracourtfeatured,

    title: "Zahra Court",
    slug: "zahra-court",
    status: "completed",
    thumbnail: images.completedproject13,
    location: "10TH ROAD, KHAR (W)",
    description:
      "Zahra Court is an ultimate reflection of the urban chic lifestyle located in Khar West, Mumbai. The project hosts in its lap exclusively designed Residential Apartments, each being an epitome of elegance and simplicity.",
    brochure: "",
    image: images.completedproject13,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Khar West",
        title: "Location : 10th Road, Khar (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area : 850 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 3 & 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7997353255873!2d72.8367852!3d19.072540699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90ef77fcb8d%3A0x28a680d8371aa59d!2sZahra%20court!5e0!3m2!1sen!2sin!4v1673956439290!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Zahra%20court%203RFP+2P7,%208th%20Rd,%20Khar,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.1 KM<p/><p>Western express highway - 2.0 KM<p/><p>Bandra Worli Sea Link - 4 KM<p/><p>Linking Road - 0.5 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.6 KM<p/><p>Neelam Foodland - 1.0 KM<p/><p>FoodHall - 1.0 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.4 KM<p/><p>Lilavati Hospital and Research Center - 2.5 KM<p/><p>Womens Hospital - 1.0 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.7 KM<p/><p>Otters Club - 2.50 KM<p/><p>Rao Saheb Patwardhan Park - 1.0 KM<p/><p>Bastian Restaurant & Bar - 1.0 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
  // {
  //   id: 20,
  //   isFeatured: "",
  //   featured_image: images.galleryplaceholder,

  //   title: "PH Villa",
  //   slug: "ph-villa",
  //   status: "completed",
  //   thumbnail: images.project_thumbnail,
  //   location: "",
  //   description: "",
  //   brochure: "",
  //   image: images.project_image,
  //   circle_text: images.ocreceived.image,
  // subNav: [
  //   {
  //     _id: "one",
  //     tabName: "OVERVIEW",
  //   },
  //   {
  //     _id: "two",
  //     tabName: "PROJECT GALLERY",
  //   },
  // {
  //   _id: "three",
  //   tabName: "AMENITIES",
  // },
  // {
  //   _id: "four",
  //   tabName: "NEARBY LOCATIONS",
  // },
  // {
  //   _id: "five",
  //   tabName: "FLOOR PLANS",
  // },
  // ],
  //   highlights: [
  //     {
  //       icon: projectlocation,
  //       title: "Location : ",
  //     },
  //     {
  //       icon: areaicon,
  //       title: "Plot Area : ",
  //     },
  //     {
  //       icon: residenceicon,
  //       title: "Residences : 3 & 4 BHK’s",
  //     },
  //     {
  //       icon: propertytypeicon,
  //       title: "Project Type : Residential Building",
  //     },
  //   ],
  //   // galleries: [
  //   //   {
  //   //     type: "image",
  //   //     thumbnail: images.project_gallery_1,
  //   //     caption: "demo caption",
  //   //   },
  //   //   {
  //   //     type: "video",
  //   //     thumbnail: images.project_gallery_2,
  //   //     caption: "demo caption 2",

  //   //     embed_code: "",
  //   //   },
  //   // ],
  //   // amenities_title: "AMENITIES",
  //   // amenities_desc:
  //   //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
  //   // amenities: [
  //   //   {
  //   //     icon: projectlocation,
  //   //     title: "",
  //   //     description: "",
  //   //     image: images.project_amenities_1,
  //   //   },
  //   //   {
  //   //     icon: residenceicon,
  //   //     title: "",
  //   //     description: "",
  //   //     image: images.project_amenities_2,
  //   //   },
  //   //   {
  //   //     icon: projectlocation,
  //   //     title: "",
  //   //     description: "",
  //   //     image: images.project_amenities_1,
  //   //   },
  //   //   {
  //   //     icon: residenceicon,
  //   //     title: "",
  //   //     description: "",
  //   //     image: images.project_amenities_2,
  //   //   },
  //   // ],
  //   map_link: "",
  //   locations: [
  //     {
  //       title: "CONNECTIVITY",
  //       description: "",
  //       // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
  //     },
  //     {
  //       title: "MALLS & SUPERMARKET",
  //       description: "",
  //       // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
  //     },
  //     {
  //       title: "Health Care",
  //       description: "",
  //       // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
  //     },
  //     {
  //       title: "Social Life",
  //       description: "",
  //       // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
  //     },
  //   ],
  //   area_details: [
  //     {
  //       title: "Plot Area",
  //       area: " Sq. Ft.",
  //     },
  //     {
  //       title: "Villa Area",
  //       area: " Sq. Ft.",
  //     },
  //   ],
  //   floor_plans: [
  //     {
  //       title: "Ground Floor",
  //       thumbnail: images.project_floor_1,
  //       file: images.project_floor_1.image,
  //     },
  //     {
  //       title: "First Floor",
  //       thumbnail: images.project_floor_2,
  //       file: images.project_floor_2.image,
  //     },
  //   ],
  //   virtualTour: [],
  // },
  {
    id: 21,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "PH Palms",
    slug: "ph-palms",
    status: "completed",
    thumbnail: images.completedproject17,
    location: " MADH ISLAND (W)",
    description: "",
    brochure: "",
    image: images.completedproject17,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      {
        _id: "two",
        tabName: "PROJECT GALLERY",
      },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      // {
      //   _id: "four",
      //   tabName: "NEARBY LOCATIONS",
      // },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
      {
        _id: "six",
        tabName: "VIRTUAL TOUR",
      },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Khar West",
        title: " MADH ISLAND (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area :  1 Acre",
      },
      // {
      //   icon: residenceicon,
      //   title: "",
      // },
      // {
      //   icon: propertytypeicon,
      //   title: "",
      // },
    ],
    galleries: [
      {
        type: "image",
        thumbnail: images.phpalmgallery3,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery4,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery5,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery6,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery7,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery8,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery9,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery10,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery11,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery12,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery13,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery14,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery15,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery16,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery17,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery21,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery1,
        caption: "PH Palm",
      },
      {
        type: "image",
        thumbnail: images.phpalmgallery2,
        caption: "PH Palm",
      },
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link: "",
    locations: [
      // {
      //   title: "CONNECTIVITY",
      //   description:
      //     "<p>SV Road - 0.1 KM<p/><p>Western express highway - 2.0 KM<p/><p>Bandra Worli Sea Link - 4 KM<p/><p>Linking Road - 0.5 KM<p/>",
      //   // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      // },
      // {
      //   title: "MALLS & SUPERMARKET",
      //   description:
      //     "<p>Link Square Mall - 0.6 KM<p/><p>Neelam Foodland - 1.0 KM<p/><p>FoodHall - 1.0 KM<p/>",
      //   // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      // },
      // {
      //   title: "Health Care",
      //   description:
      //     "<p>PD Hinduja Hospital Khar - 0.4 KM<p/><p>Lilavati Hospital and Research Center - 2.5 KM<p/><p>Womens Hospital - 1.0 KM<p/>",
      //   // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      // },
      // {
      //   title: "Social Life",
      //   description:
      //     "<p>Khar Gymkhana - 0.7 KM<p/><p>Otters Club - 2.50 KM<p/><p>Rao Saheb Patwardhan Park - 1.0 KM<p/><p>Bastian Restaurant & Bar - 1.0 KM<p/>",
      //   // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      // },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [
      {
        title: "",
        thumbnail: images.virtual_1.image,
        link: "https://youtu.be/Ba1RxHj6T6Q",
      },
    ],
  },
  {
    id: 22,
    isFeatured: "",
    featured_image: images.galleryplaceholder,

    title: "Bhagwanti Heights",
    slug: "bhagwanti-heights",
    status: "completed",
    thumbnail: images.completedproject18,
    location: "14th Road, Khar (W)",
    description:
      "Situated on 14th Road West, this building is planned to offer a modern lifestyle to all the residents. With lavish wrap-around balconies and larger-than-life living room sizes, this project is a masterpiece by itself.",
    brochure: "",
    image: images.completedproject18,
    circle_text: images.completed.image,
    subNav: [
      {
        _id: "one",
        tabName: "OVERVIEW",
      },
      // {
      //   _id: "two",
      //   tabName: "PROJECT GALLERY",
      // },
      // {
      //   _id: "three",
      //   tabName: "AMENITIES",
      // },
      {
        _id: "four",
        tabName: "NEARBY LOCATIONS",
      },
      // {
      //   _id: "five",
      //   tabName: "FLOOR PLANS",
      // },
    ],
    highlights: [
      {
        icon: projectlocation,
        // title: "Location : Bandra (W)",
        title: "Location : 14TH ROAD, KHAR (W)",
      },
      {
        icon: areaicon,
        title: "Plot Area :  600 sq. meters",
      },
      {
        icon: residenceicon,
        title: "Residences : 4 BHK",
      },
      {
        icon: propertytypeicon,
        title: "Project Type : Residential Building",
      },
    ],
    galleries: [
      //   {
      //     type: "image",
      //     thumbnail: images.project_gallery_1,
      //     caption: "demo caption",
      //   },
      //   {
      //     type: "video",
      //     thumbnail: images.project_gallery_2,
      //     caption: "demo caption 2",
      //     embed_code: "",
      //   },
    ],
    // amenities_title: "AMENITIES",
    // amenities_desc:
    //   "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat. sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren. Lorem ipsum dolor sit amet, consetetur dolor ser sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
    amenities: [
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
      //   {
      //     icon: projectlocation,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_1,
      //   },
      //   {
      //     icon: residenceicon,
      //     title: "",
      //     description: "",
      //     image: images.project_amenities_2,
      //   },
    ],
    map_link:
      // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9314801587348!2d72.83278!3d19.06675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c91233e5f26f%3A0x7331d9dbdd311f34!2sBhagwanti%20Heights!5e0!3m2!1sen!2sin!4v1680149226496!5m2!1sen!2sin",
      "https://maps.google.com/maps?q=Bhagwanti%20Heights%20469,%2014th%20Lane,%20Pali%20Village,%20Khar%20West,%20Mumbai,%20Maharashtra%20400052&t=&z=19&ie=UTF8&iwloc=&output=embed",
    locations: [
      {
        title: "CONNECTIVITY",
        description:
          "<p>SV Road - 0.8 KM<p/><p>Western express highway - 3.0 KM<p/><p>Bandra Worli Sea Link - 4.1 KM<p/><p>Linking Road - 0.3 KM<p/>",
        // "<p>Santacruz Chembur Link Road – 2.00 Km </p><p>Bkc – 4.00 Km </p><p>Eastern Express Highway – 4.00 Km</p>",
      },
      {
        title: "MALLS & SUPERMARKET",
        description:
          "<p>Link Square Mall - 0.25 KM<p/><p>Neelam Foodland - 0.7 KM<p/><p>FoodHall - 1.1 KM<p/>",
        // "<p>Jio World Drive – 5.3 Km </p><p>Cubic Mall, Chembur – 2.00 Km </p><p>Sahakari Bhandar – 2.00 Km</p>",
      },
      {
        title: "Health Care",
        description:
          "<p>PD Hinduja Hospital Khar - 0.5 KM<p/><p>Lilavati Hospital and Research Center - 3.0 KM<p/><p>Womens Hospital - 0.55 KM<p/>",
        // "<p>Apollo Spectra Hospital – 3.3 KM </p><p>Surana Sethia Hospital – 1.00 KM </p><p>OMA WOMENS HOSPITAL – 2.00 KM</p>",
      },
      {
        title: "Social Life",
        description:
          "<p>Khar Gymkhana - 0.5 KM<p/><p>Otters Club - 2.2 KM<p/><p>Rao Saheb Patwardhan Park - 0.55 KM<p/><p>Bastian Restaurant & Bar - 0.7 KM<p/>",
        // "<p>Sindhi Society Gymkhana – 0.02 Km </p><p>The Acres Club – 0.3 Km </p><p>The Bombay Presidency Golf Club, Chembur – 1.2 Km </p><p>Movie Time Cinemas – 2.2 Km </p><p>Wework, Bkc- 4.00 Km </p><p>Chembur Social – 2.5km</p>",
      },
    ],
    area_details: [
      // {
      //   title: "Plot Area",
      //   area: " Sq. Ft.",
      // },
      // {
      //   title: "Villa Area",
      //   area: " Sq. Ft.",
      // },
    ],
    floor_plans: [
      // {
      //   title: "Ground Floor",
      //   thumbnail: images.project_floor_1,
      //   file: images.project_floor_1.image,
      // },
      // {
      //   title: "First Floor",
      //   thumbnail: images.project_floor_2,
      //   file: images.project_floor_2.image,
      // },
    ],
    virtualTour: [],
  },
];

export const findAll = (category_slug = null) => {
  if (category_slug) {
    // return _.find(datas, { 'category': category_slug });
    return _.filter(datas, { category: category_slug });
  } else {
    return datas;
  }
};

export const findBySlug = (slug) => {
  return _.find(datas, { slug: slug });
};

export const findWhere = (filter = {}) => {
  return _.filter(datas, filter);
};
