import React, { useState, useRef, useEffect } from "react";
import "./floorPlan.scss";
import { downloadIcon, minusIcon, plusIcon } from "../../images";

import { useWindowSize } from "react-use";
import CommonCtaV2 from "../CommonCtaHillView/CommonCtaV2";

const FloorPlan = ({ dataList, handleFloorPlanBrochure }) => {
  const { width } = useWindowSize();
  const [isClicked, setIsClicked] = useState(0);
  const activeAccordionRef = useRef(null);
  const handleItemClick = (index) => {
    setIsClicked((prevIndex) => (prevIndex === index ? null : index));
  };

  const scrollToActiveAccordion = () => {
    if (activeAccordionRef.current) {
      activeAccordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    scrollToActiveAccordion();
  }, [isClicked]);

  return (
    <>
      <div className="floorplan_wrapper">
        <div className="my_container_2">
          <div className="row plan_row">
            <div className="col-lg-5 plan_col">
              <div className="content_wrapper">
                <h2 className="section_heading">FLOOR PLANS</h2>
                {dataList.map((acc, index) => (
                  <div
                    className={`accordion-btn-wrapper ${
                      isClicked === index ? "active" : ""
                    }`}
                    key={index}
                    ref={isClicked === index ? activeAccordionRef : null}
                  >
                    <div
                      className={`btn_wrapper ${
                        isClicked === index ? "active" : ""
                      }`}
                      onClick={() => handleItemClick(index)}
                    >
                      <h4
                        className={`section_subheading32 ${
                          isClicked === index ? "active" : ""
                        }`}
                      >
                        {acc.heading}
                      </h4>
                      <img
                        src={isClicked === index ? minusIcon : plusIcon}
                        alt="arrow"
                        className={`acc_icon ${
                          isClicked === index ? "active" : ""
                        }`}
                      />
                    </div>

                    <div
                      className={`acc_items ${
                        isClicked === index ? "active" : ""
                      }`}
                    >
                      {acc?.description.map((desc, index) => (
                        <p
                          key={index}
                          className="section_desc"
                          dangerouslySetInnerHTML={{ __html: desc }}
                        ></p>
                      ))}
                      {width <= 992 && (
                        <div className="img_wrapper" key={index}>
                          <p className="rera_no">{acc.rera}</p>
                          <img
                            src={acc.img}
                            alt={acc.alt}
                            className="plan_img"
                          />
                          <div
                            onClick={handleFloorPlanBrochure}
                            className="pdf_link"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={downloadIcon}
                              alt="download icon"
                              className="download_icon"
                            />
                          </div>
                        </div>
                      )}
                      <div
                        onClick={handleFloorPlanBrochure}
                        style={{ cursor: "pointer" }}
                      >
                        <CommonCtaV2
                          linkCta={false}
                          // externalLink={true}
                          linkText="Download Brochure"
                          // link={acc.planpdf}
                          // isDownolad={true}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {width > 992 && (
              <div className="col-lg-7 plan_col">
                {dataList.map((acc, index) => (
                  <div
                    className={`img_wrapper ${
                      isClicked === index ? "active" : ""
                    }`}
                    key={index}
                  >
                    <p className="rera_no">{acc.rera}</p>
                    <img src={acc.img} alt={acc.alt} className="plan_img" />
                    {/* <p className="floor_no">GROUND FLOOR</p> */}
                    <div
                      onClick={handleFloorPlanBrochure}
                      className="pdf_link"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <img
                        src={downloadIcon}
                        alt="download icon"
                        className="download_icon"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FloorPlan;
